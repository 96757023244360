import {type FC, useRef, useEffect} from "react"
import WebViewer from "@pdftron/webviewer"
import {useConfigProvider} from "../../../config"
import {FileError} from "../fileError/FileError"

type Props = {
  file?: string | File | Blob
  fileType?: string
  fileName?: string
  handleReset?: () => void
  setIsViewerLoaded: React.Dispatch<React.SetStateAction<boolean>>
  error: {
    hasFileError: boolean
    setHasFileError: React.Dispatch<React.SetStateAction<boolean>>
  }
  disableHeader?: boolean
}

export const ApryseFileViewer: FC<Props> = (props) => {
  const {file, fileName, fileType, error, disableHeader, setIsViewerLoaded} =
    props
  const {apryseKey} = useConfigProvider()
  const viewer = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (viewer.current) {
      WebViewer(
        {
          path: "/webviewer/lib",
          // deepcode ignore HardcodedNonCryptoSecret: Secret is not hardcoded, that is just the name of the key
          licenseKey:
            apryseKey || "Insert commercial license key here after purchase",
          initialDoc: typeof file === "string" ? file : undefined,
          disabledElements: [
            "leftPanelButton",
            "panToolButton",
            "selectToolButton",
            "viewControlsButton",
            "toolsHeader",
            "settingsButton",
            "menuButton",
            disableHeader ? "header" : "",
          ],
        },
        viewer.current!,
      ).then((instance): any => {
        const {UI, Core} = instance
        Core.disableLogs(true)
        Core.disableEmbeddedJavaScript()
        if (file && typeof file !== "string") {
          UI.loadDocument(file, {filename: fileName, extension: fileType})
        }
        if (
          fileType === "csv" ||
          (file && typeof file === "string" && file.includes(".csv"))
        ) {
          UI.disableElements(["errorModal", "progressModal"])
          UI.showWarningMessage({
            title: "Error Loading Document",
            message:
              "Please be aware the preview of this file is not supported",
            confirmBtnText: "OK",
            onConfirm: () => {
              return Promise.resolve()
            },
            onCancel: () => {
              return Promise.resolve()
            },
          })
        }
        UI.disableTools()
        UI.disableFeatures([
          UI.Feature.SideBySideView,
          UI.Feature.WatermarkPanel,
          UI.Feature.PageNavigation,
          UI.Feature.Print,
          UI.Feature.MultiTab,
          UI.Feature.Search,
          UI.Feature.NotesPanel,
          UI.Feature.Ribbons,
          UI.Feature.FilePicker,
          UI.Feature.ContentEdit,
          UI.Feature.Portfolio,
          UI.Feature.ComparePages,
          UI.Feature.Redaction,
          UI.Feature.TextSelection,
          UI.Feature.SideBySideView,
          UI.Feature.ContentEdit,
        ])
        // UI.setZoomLevel("100%")
        UI.setFitMode(UI.FitMode.FitWidth)
        UI.setLayoutMode(UI.LayoutMode.Continuous)
        UI.setHeaderItems((header) => {
          const items = header.getItems()
          header.update([
            ...items.filter((item: any) => item.type !== "divider"),
          ])
        })
        const docViewer = Core.documentViewer
        docViewer.addEventListener("documentLoaded", () => {
          setIsViewerLoaded(true)
        })
        UI.addEventListener("loaderror", () => {
          if (
            fileType === "csv" ||
            (file && typeof file === "string" && file.includes(".csv"))
          ) {
            setIsViewerLoaded(true)
            return
          }
          error.setHasFileError(true)
          setIsViewerLoaded(true)
        })
      })
    }

    return () => {
      viewer.current = null
    }
  }, [file, fileName, apryseKey, fileType])

  if (
    error.hasFileError &&
    !(
      fileType === "csv" ||
      (file && typeof file === "string" && file.includes(".csv"))
    )
  )
    return <FileError fileName={fileName ? fileName : undefined} />
  return (
    <div
      className="webviewer"
      ref={viewer}
      style={{width: "100%", flexGrow: 1, position: "relative"}}
    ></div>
  )
}
