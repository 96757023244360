import {Box, Skeleton, Stack, Typography, useTheme} from "@mui/material"
import {FC, useEffect, useMemo, useRef, useState} from "react"
import {ReactSVG} from "react-svg"
import {useInfiniteQuery, keepPreviousData} from "@tanstack/react-query"
import {useInView} from "react-intersection-observer"
import {decodeToken} from "react-jwt"
import {projectsEndpoints} from "../../services/api/projects"
import type {PagedResult, ProjectAssignmentModel} from "../../types"
import type {Token} from "../../types/Token"
import {useClientSessionStore} from "../../stores/clientSessionStore"
import usePageStore from "../../stores/pageStore"
import useStorage, {StorageData} from "../../hooks/useStorage"
import {
  NewButton,
  Popover,
  SearchBarr,
  ProjectGridView,
  ProjectListView,
} from "../../components"
import {useConfigProvider} from "../../config"
import {ArrowDropDownIcon, ChevronDownIcon} from "../../assets/icons"
import {useTranslation} from "react-i18next"
import {CR_ACCOUNT_NUMBER} from "../../constants"

const sortOptions = [
  {label: "Project name", value: "projectName"},
  {label: "Created", value: "publishedDate"},
]

const listLimit = 6
const gridLimit = 8

const Home: FC = () => {
  const theme = useTheme()
  const {t} = useTranslation()
  const [filters, setFilters] = useState<{
    searchText: string
  }>({searchText: ""})
  const [sortOption, setSortOption] = useState<{
    sortBy: string
    sortOrder: string
  }>({sortBy: "publishedDate", sortOrder: "desc"})
  const {getProjectsByUserId} = projectsEndpoints()
  const [viewMode, setViewMode] = useState<"list" | "grid">("grid")
  const [openModeMenu, setOpenModeMenu] = useState(false)
  const [openSortMenu, setOpenSortMenu] = useState(false)
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const modeAnchorRef = useRef<HTMLButtonElement>(null)
  const sortAnchorRef = useRef<HTMLButtonElement>(null)
  const {inView, ref} = useInView()
  const {token} = useConfigProvider()
  const decoded = decodeToken<Token>(token)

  const sessionClient = useClientSessionStore((state) => state.crmAccount)
  const setSessionClient = useClientSessionStore((state) => state.setClient)
  const clearSessionClient = useClientSessionStore((state) => state.clearClient)
  const storage = useStorage("session", StorageData.crmAccount)

  const queryString = useMemo(() => {
    const query = `${filters.searchText}`
    const sortBy = `${sortOption.sortOrder === "desc" ? "-" : ""}${
      sortOption.sortBy
    }`
    const limit = viewMode === "list" ? listLimit : gridLimit
    const crm =
      decoded?.companyId === CR_ACCOUNT_NUMBER
        ? sessionClient
        : decoded?.companyId || ""
    return `limit=${limit}&query=${
      query ? encodeURIComponent(query) : ""
    }&sortBy=${sortBy}&crm=${crm}`
  }, [
    filters,
    sortOption.sortBy,
    sortOption.sortOrder,
    viewMode,
    sessionClient,
  ])

  useEffect(() => {
    //TODO we need to come up with a better way to handle 001 logic
    if (decoded?.companyId !== CR_ACCOUNT_NUMBER) return
    if (!storage.get()) {
      clearSessionClient()
    } else {
      setSessionClient(storage.get() || "")
    }
  }, [storage])

  const lazyProjectsQuery = useInfiniteQuery<
    PagedResult<ProjectAssignmentModel>
  >({
    queryKey: ["lazyProjects", queryString],
    initialPageParam: 1,
    enabled: !!sessionClient,
    queryFn: ({pageParam}) => {
      const page = parseInt(pageParam as string) || 1
      const limit = viewMode === "list" ? listLimit : gridLimit
      const offset = (page - 1) * limit
      const paginatedQuery = `${queryString}&offset=${offset}`
      return getProjectsByUserId(paginatedQuery)
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.currentPage < lastPage.pageCount) {
        return lastPage.currentPage + 1
      }
      return undefined
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  useEffect(() => {
    if (
      filters.searchText &&
      (lazyProjectsQuery.isLoading ||
        lazyProjectsQuery.isFetching ||
        lazyProjectsQuery.isRefetching)
    ) {
      setIsLoadingSearch(true)
    } else {
      setIsLoadingSearch(false)
    }
  }, [
    filters.searchText,
    lazyProjectsQuery.isLoading,
    lazyProjectsQuery.isFetching,
    lazyProjectsQuery.isRefetching,
  ])

  useEffect(() => {
    if (inView && lazyProjectsQuery.hasNextPage) {
      lazyProjectsQuery.fetchNextPage()
    }
  }, [
    inView,
    lazyProjectsQuery.hasNextPage,
    lazyProjectsQuery.fetchNextPage,
    ref,
  ])

  const filteredData = useMemo(() => {
    const reducedClients = lazyProjectsQuery?.data?.pages?.reduce(
      (acc, {results}) => [...acc, ...results],
      [] as ProjectAssignmentModel[],
    )
    return reducedClients
  }, [lazyProjectsQuery?.data])

  const handleViewModeChange = (mode: "list" | "grid") => {
    setViewMode(mode)
  }

  const handleSearchChange = (value: string) => {
    setFilters((prevState) => ({
      ...prevState,
      searchText: value.toLowerCase(),
    }))
  }

  const handleToggleModeMenu = () => {
    setOpenModeMenu((prev) => !prev)
  }

  const handleToggleSortMenu = () => {
    setOpenSortMenu((prev) => !prev)
  }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage("General")
  }, [])

  return (
    <Stack
      component="main"
      height="100%"
      direction="column"
      gap="0.5rem"
      paddingX={{
        xs: "1rem",
        md: "2rem",
        xl: "6rem",
      }}
      sx={{
        paddingTop: "1.5rem",
        paddingBottom: "2.5rem",
      }}
    >
      <Stack component="header" direction="column" gap="0.5rem">
        <Stack
          component="section"
          direction="row"
          gap="1.5rem"
          alignItems="center"
          justifyContent="flex-end"
        >
          <SearchBarr
            state="collapsed"
            size="small"
            containerProps={{sx: {width: "15rem"}}}
            placeholder={t("Search")}
            onChange={handleSearchChange}
            disabled={
              (lazyProjectsQuery.isLoading ||
                (!lazyProjectsQuery?.isLoading &&
                  (!lazyProjectsQuery?.data || filteredData?.length === 0))) &&
              filters.searchText.length === 0
            }
          />
          <NewButton
            icon
            ref={modeAnchorRef}
            variant="text"
            color="primary"
            onClick={handleToggleModeMenu}
            disabled={
              (lazyProjectsQuery.isLoading ||
                (!lazyProjectsQuery?.isLoading &&
                  (!lazyProjectsQuery?.data || filteredData?.length === 0))) &&
              filters.searchText.length === 0
            }
            IconLeft={
              <ReactSVG
                src={`/icons/${
                  viewMode === "list" ? "list-view" : "grid-view"
                }${viewMode === "list" ? "-selected" : ""}.svg`}
                color="#595958"
              />
            }
            IconRight={
              <ArrowDropDownIcon
                color="#595958"
                height="0.75rem"
                width="0.75rem"
              />
            }
            sx={{
              width: "auto",
              minWidth: "auto",
              padding: "0.4375rem 0.5rem",
              color: "#595958",
              "> svg": {
                height: "0.75rem",
                width: "0.75rem",
              },
              "div > div": {
                height: "1.5rem",
                width: "1.5rem",

                svg: {
                  height: "1.5rem",
                  width: "1.5rem",
                },
              },
            }}
          />
          <Popover
            anchorEl={modeAnchorRef.current}
            open={openModeMenu}
            handleClose={handleToggleModeMenu}
            items={[
              {
                text: t("View mode"),
                section: true,
                items: [
                  {
                    text: t("List view"),
                    Icon: <ReactSVG src="/icons/list-view.svg" />,
                    selected: viewMode === "list",
                    onClick: () => {
                      handleViewModeChange("list")
                      handleToggleModeMenu()
                    },
                  },
                  {
                    text: t("Grid view"),
                    Icon: <ReactSVG src="/icons/grid-view.svg" />,
                    selected: viewMode === "grid",
                    onClick: () => {
                      handleViewModeChange("grid")
                      handleToggleModeMenu()
                    },
                  },
                ],
              },
            ]}
          />
        </Stack>
        <Stack
          component="section"
          direction="row"
          gap="0.5rem"
          alignItems="center"
          justifyContent="space-between"
        >
          {filters.searchText.length > 0 ? (
            <Stack direction="row" gap="0.5rem" alignItems="center">
              <Typography
                component="h1"
                fontSize="1rem"
                fontWeight="600"
                lineHeight="1.5rem"
                color="#242D35"
                margin="0"
              >
                {t("Search results")}
              </Typography>
              <Stack
                height="1.5rem"
                width="1.5rem"
                alignContent="center"
                justifyContent="center"
                borderRadius="100%"
                bgcolor="#BDBDBC"
                textAlign="center"
              >
                <Typography
                  fontSize="0.875rem"
                  lineHeight="1.25rem"
                  fontWeight="500"
                  color="#242D35"
                  textAlign="center"
                >
                  {!lazyProjectsQuery.isLoading && !isLoadingSearch ? (
                    lazyProjectsQuery?.data?.pages?.at(0)?.results.length || 0
                  ) : (
                    <Skeleton variant="text" width="1.5rem" height="1.5rem" />
                  )}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Typography
              component="h1"
              fontSize="1rem"
              fontWeight="600"
              lineHeight="1.5rem"
              color="#242D35"
              margin="0"
            >
              {t("Your projects")}
            </Typography>
          )}
          <NewButton
            icon
            ref={sortAnchorRef}
            text={t("Sort")}
            variant="text"
            color="primary"
            onClick={handleToggleSortMenu}
            disabled={
              (lazyProjectsQuery.isLoading ||
                (!lazyProjectsQuery?.isLoading &&
                  (!lazyProjectsQuery?.data || filteredData?.length === 0))) &&
              filters.searchText.length === 0
            }
            sx={{
              width: "auto",
              minWidth: "auto",
              color: "#595958",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 400,
              svg: {
                height: "1rem",
                width: "1rem",
                color: "#595958",
              },
            }}
            IconRight={<ChevronDownIcon color="#595958" />}
          />
          <Popover
            anchorEl={sortAnchorRef.current}
            open={openSortMenu}
            handleClose={handleToggleSortMenu}
            items={[
              {
                text: t("Sort by"),
                Icon: (
                  <NewButton
                    icon
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setSortOption((prev) => ({
                        ...prev,
                        sortOrder: prev.sortOrder === "asc" ? "desc" : "asc",
                      }))
                      handleToggleSortMenu()
                    }}
                    IconLeft={
                      sortOption.sortOrder === "asc" ? (
                        <ReactSVG src="/icons/sort-ascending.svg" />
                      ) : (
                        <ReactSVG src="/icons/sort-descending.svg" />
                      )
                    }
                    sx={{
                      padding: "0",
                      width: "1.875rem",
                      height: "1.875rem",
                      minWidth: "unset",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "div > div": {
                        height: "1rem",
                        width: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        svg: {
                          height: "1rem",
                          width: "1rem",
                        },
                      },
                    }}
                  />
                ),
                section: true,
                items: sortOptions.map((option) => ({
                  text: t(option.label),
                  selected: sortOption.sortBy === option.value,
                  onClick: () => {
                    setSortOption((prev) => ({
                      ...prev,
                      sortBy: option.value,
                      sortOrder:
                        option.value === prev.sortBy
                          ? prev.sortOrder === "asc"
                            ? "desc"
                            : "asc"
                          : prev.sortOrder,
                    }))
                    handleToggleSortMenu()
                  },
                })),
              },
            ]}
          />
        </Stack>
      </Stack>
      <Box
        component="hr"
        sx={{
          border: 0,
          borderTopWidth: "1px",
          borderTopStyle: "solid",
          borderTopColor: "#EEEEEE",
        }}
      />
      <Stack
        component="section"
        direction="column"
        gap="0.25rem"
        sx={{
          position: "relative",
          paddingTop: "0.5rem",
          paddingRight:
            (viewMode === "grid" && (filteredData?.length || 0) > 6) ||
            (viewMode === "list" && (filteredData?.length || 0) >= 6) ||
            lazyProjectsQuery.isLoading ||
            isLoadingSearch
              ? "0.5rem"
              : "0.25rem",
          height: "100%",
          overflow: "auto",
          "&:hover": {
            paddingRight: "0.25rem",
          },
        }}
      >
        {!lazyProjectsQuery?.isLoading &&
        !lazyProjectsQuery?.isFetching &&
        (!lazyProjectsQuery?.data || filteredData?.length === 0) &&
        filters.searchText.length !== 0 ? (
          <Stack textAlign="center" alignItems="center">
            <Typography color={theme.palette.info.dark}>
              {t("No matches found.")}
            </Typography>
          </Stack>
        ) : !lazyProjectsQuery?.isLoading &&
          !lazyProjectsQuery?.isFetching &&
          (!lazyProjectsQuery?.data || filteredData?.length === 0) ? (
          <Stack textAlign="center" alignItems="center">
            <Typography color={theme.palette.info.dark}>
              {t("You have no projects allocated to you.")}
            </Typography>
          </Stack>
        ) : viewMode === "list" ? (
          <ProjectListView
            projects={filteredData || []}
            isLoading={lazyProjectsQuery?.isLoading || isLoadingSearch}
            isFetching={lazyProjectsQuery?.isFetching}
            LazyLoadingIndicator={<Box ref={ref} sx={{height: "1px"}} />}
          />
        ) : (
          <ProjectGridView
            projects={filteredData || []}
            isLoading={lazyProjectsQuery?.isLoading || isLoadingSearch}
            isFetching={lazyProjectsQuery?.isFetching}
            LazyLoadingIndicator={<Box ref={ref} sx={{height: "1px"}} />}
          />
        )}
      </Stack>
    </Stack>
  )
}

Home.displayName = "Home"

export default Home
