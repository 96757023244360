import {FloatingToolbar, LoadingIndicator} from "@cr/webreport-viewer"
import {Box, Stack} from "@mui/material"
import {useQuery} from "@tanstack/react-query"
import {Fragment, useEffect, useRef, useState, type FC} from "react"
import {Navigate, useNavigate, useParams} from "react-router-dom"
import {deliverableEndpoints, projectsEndpoints} from "../../services"
import type {
  DeliverableModel,
  Embed,
  FolderModel,
  ProjectAssignmentModel,
} from "../../types"
import {TableauEmbed} from "../../components"
import AccessDenied from "../AccessDenied/AccessDenied"
import usePageStore from "../../stores/pageStore"
import {useConfigProvider} from "../../config"
import {PowerBIEmbed} from "powerbi-client-react"
import {models} from "powerbi-client"

const ViewDashboard: FC = () => {
  const {getUserProjectById, getFolderById} = projectsEndpoints()
  const {getDeliverable, getTableauPreview, getPowerBIPreview} =
    deliverableEndpoints()
  const {id, folderId, dashboardId} = useParams<{
    id: string
    folderId: string
    dashboardId: string
  }>()
  const navigate = useNavigate()
  const {basename} = useConfigProvider()
  const [mode, setMode] = useState<"normal" | "full-screen" | "presentation">(
    "normal",
  )
  const [loaded, setLoaded] = useState(false)
  const parentRef = useRef<HTMLDivElement | null>(null)
  const viewerRef = useRef(null)

  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["getProject", id],
    queryFn: () => getUserProjectById(id || ""),
  })

  const folderQuery = useQuery<FolderModel>({
    queryKey: ["getFolder", id, folderId],
    queryFn: () => {
      return getFolderById(id || "", folderId || "")
    },
  })

  const deliverableQuery = useQuery<DeliverableModel>({
    queryKey: ["getDeliverable", dashboardId],
    queryFn: () => getDeliverable(dashboardId || ""),
  })

  const deliverable = deliverableQuery.data

  const tableauRegion = (() => {
    const deliverableUrl = deliverableQuery?.data?.deliverableUrl
    if (deliverableUrl && deliverableUrl.includes("tableau")) {
      const regex = /https:\/\/(.*)?tableau\.(.*)\.com\/(.*)/
      const match = regex.test(deliverableUrl)

      if (match) {
        return (
          deliverableUrl
            ?.split("/")
            ?.at(2)
            ?.split(".")
            ?.at(0)
            ?.split("-")
            ?.at(0) || "uk"
        )
      }
    }
    return "uk"
  })()

  const tableauPreview = useQuery<Embed>({
    queryKey: ["tableau", tableauRegion, id, folderId],
    queryFn: () => {
      return getTableauPreview(tableauRegion)
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: deliverableQuery?.data?.deliverableSource === "Tableau",
  })

  const powerBIUrl = (() => {
    const deliverableUrl = deliverableQuery?.data?.deliverableUrl
    if (deliverableUrl && deliverableUrl.includes("powerbi")) {
      const regex = /https:\/\/(.*\.)?powerbi\.com\/(.*)/
      const match = regex.test(deliverableUrl)

      if (match) {
        return deliverableUrl?.split("/")
      }
    }
    return undefined
  })()

  const pbiWorkspaceId = powerBIUrl?.at(4)
  const pbiDashboardId = powerBIUrl?.at(6)

  const powerBiPreview = useQuery<Embed>({
    queryKey: ["powerbi", pbiDashboardId, pbiWorkspaceId],
    queryFn: () =>
      getPowerBIPreview(pbiWorkspaceId || "", pbiDashboardId || ""),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled:
      deliverableQuery?.data?.deliverableSource === "Powerbi" &&
      !!pbiWorkspaceId &&
      !!pbiDashboardId,
  })

  const handleClose = () => {
    navigate(-1)
  }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage(deliverableQuery.data?.deliverableName || "General")
  }, [deliverableQuery.data?.deliverableName])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setMode("normal")
        if (mode === "presentation" && document.fullscreenElement) {
          document.exitFullscreen()
        }
      }
    }
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        if (mode === "presentation") {
          setMode("normal")
        }
      }
    }

    document.addEventListener("fullscreenchange", handleFullScreenChange)
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
      document.removeEventListener("fullscreenchange", handleFullScreenChange)
    }
  }, [mode])

  const handleFullScreenMode = () => {
    if (mode === "full-screen") {
      setMode("normal")
    } else {
      setMode("full-screen")
      if (mode === "presentation" && document.fullscreenElement) {
        document.exitFullscreen()
      }
    }
  }

  const handlePresentationMode = () => {
    if (mode === "presentation") {
      setMode("normal")
      document.exitFullscreen()
    } else {
      setMode("presentation")
      parentRef.current
        ?.requestFullscreen({navigationUI: "hide"})
        .catch((err) => {
          console.info("Error attempting to enable full-screen mode:", err)
        })
    }
  }

  if (deliverableQuery?.data?.hasOwnProperty("title")) {
    return <AccessDenied resource="deliverable" />
  }

  if (deliverableQuery?.data?.expiryDate) {
    const deliverableExpiryDate = Date.parse(
      deliverableQuery?.data?.expiryDate
        ? deliverableQuery?.data?.expiryDate
        : "",
    )
    const currentDate = Date.parse(new Date().toISOString())

    if (currentDate > deliverableExpiryDate) {
      return (
        <AccessDenied
          resource="deliverable"
          resourceName={deliverableQuery?.data?.deliverableName}
        />
      )
    }
  }

  if (
    (!deliverableQuery.data?.enabled &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (!projectQuery.data?.enabled &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending) ||
    (!folderQuery.data?.isEnabled &&
      !folderQuery.isLoading &&
      !folderQuery.isFetching &&
      !folderQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  if (
    (folderId !== deliverableQuery.data?.folder?.folderId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (id !== deliverableQuery.data?.projectAssignmentId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  if (deliverableQuery?.data?.deliverableType === "web") {
    return (
      <Navigate
        to={`${basename}/${id}/folders/${folderId}/web-report/${dashboardId}`}
        replace
      />
    )
  }

  if (deliverableQuery?.data?.deliverableType === "report") {
    return (
      <Navigate
        to={`${basename}/${id}/folders/${folderId}/file/${dashboardId}`}
        replace
      />
    )
  }

  return (
    <Box
      component="main"
      paddingX="2rem"
      paddingTop="1rem"
      paddingBottom="3rem"
      width="100%"
    >
      <Stack direction="column" gap="0.5rem">
        <Stack direction="column" gap="0.5rem" component="section">
          <Box
            sx={{
              backgroundColor: mode === "full-screen" ? "unset" : "#FFFFFF",
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);",
              borderRadius: "0.5rem",
              padding: "2.5rem",
              position: "fixed",
              zIndex: mode === "full-screen" ? 1110 : 1010,
              height: "100vh",
              width: mode == "full-screen" ? "100vw" : "calc(100vw - 56px)",
              top: 0,
              right: 0,
            }}
          >
            {deliverableQuery.isLoading ||
            tableauPreview.isLoading ||
            powerBiPreview.isLoading ||
            !deliverable ? (
              <Fragment>
                <FloatingToolbar
                  mode={mode}
                  loading
                  handleClose={handleClose}
                  handleDownload={() => {}}
                  handleFullScreenMode={() => {}}
                  handlePresentationMode={() => {}}
                />
                <LoadingIndicator />
              </Fragment>
            ) : (
              <Fragment>
                <div
                  ref={parentRef}
                  style={{
                    position: mode === "full-screen" ? "fixed" : "initial",
                    top: 0,
                    left: 0,
                    width: mode === "normal" ? "auto" : "100vw",
                    height: mode === "normal" ? "auto" : "100vh",
                    zIndex: mode === "full-screen" ? 1100 : 90,
                    backgroundColor:
                      mode === "full-screen" ? "#1b1b1bcc" : "transparent",
                  }}
                >
                  <FloatingToolbar
                    ref={parentRef}
                    mode={mode}
                    presentationMode
                    fullScreenMode
                    loading={
                      deliverableQuery.isLoading ||
                      tableauPreview.isLoading ||
                      powerBiPreview.isLoading ||
                      !deliverable ||
                      !loaded
                    }
                    handleClose={handleClose}
                    handleDownload={() => {}}
                    handleFullScreenMode={handleFullScreenMode}
                    handlePresentationMode={handlePresentationMode}
                    containerProps={{
                      style: {
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                      },
                    }}
                  />
                  {deliverable?.deliverableSource === "Tableau" ? (
                    <Box
                      component="section"
                      id="tableau-embed"
                      ref={viewerRef}
                      title={deliverable.deliverableName}
                      width={
                        mode === "presentation"
                          ? window.innerWidth
                          : window.innerWidth - 56
                      }
                      height={
                        mode === "presentation"
                          ? window.innerHeight
                          : mode === "full-screen"
                          ? window.innerHeight - 48
                          : window.innerHeight - 88
                      }
                      sx={{
                        border: "none",
                        height:
                          mode === "presentation"
                            ? "100%"
                            : mode === "full-screen"
                            ? "calc(100vh - 48px)"
                            : "calc(100vh - 88px)",
                        width:
                          mode === "normal" ? "calc(100vw - 56px)" : "100vw",
                        position: mode === "presentation" ? "initial" : "fixed",
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                        left: mode === "normal" ? 56 : 0,
                        zIndex: 90,
                        "#tableauViz": {
                          height: "100% !important",
                          width: "100% !important",
                        },
                        iframe: {
                          width: "100% !important",
                          height: "100% !important",
                        },
                      }}
                    >
                      <TableauEmbed
                        src={deliverableQuery.data?.deliverableUrl}
                        token={tableauPreview.data?.token}
                        hideTabs
                        toolbar="hidden"
                        articleStyle={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </Box>
                  ) : deliverable?.deliverableSource === "Powerbi" ? (
                    <Box
                      component="section"
                      id="powerbi-embed"
                      title={deliverable.deliverableName}
                      width={
                        mode === "presentation"
                          ? window.innerWidth
                          : window.innerWidth - 56
                      }
                      height={
                        mode === "presentation"
                          ? window.innerHeight
                          : mode === "full-screen"
                          ? window.innerHeight - 48
                          : window.innerHeight - 88
                      }
                      sx={{
                        border: "none",
                        height:
                          mode === "presentation"
                            ? "100%"
                            : mode === "full-screen"
                            ? "calc(100vh - 48px)"
                            : "calc(100vh - 88px)",
                        width:
                          mode === "normal" ? "calc(100vw - 56px)" : "100vw",
                        position: mode === "presentation" ? "initial" : "fixed",
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                        left: mode === "normal" ? 56 : 0,
                        zIndex: 90,
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <PowerBIEmbed
                        ref={viewerRef}
                        cssClassName="Embed-container"
                        eventHandlers={
                          new Map([["loaded", () => setLoaded(true)]])
                        }
                        embedConfig={{
                          type: "report",
                          id: pbiDashboardId || "",
                          embedUrl: powerBiPreview.data?.dashboardUrlEmbed,
                          accessToken: powerBiPreview.data?.token,
                          tokenType: models.TokenType.Embed,
                          settings: {
                            panes: {
                              filters: {
                                expanded: false,
                                visible: false,
                              },
                            },
                            background: models.BackgroundType.Default,
                          },
                        }}
                      />
                    </Box>
                  ) : null}
                  <LoadingIndicator
                    containerProps={{
                      component: "section",
                      direction: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        mode === "presentation"
                          ? window.innerWidth
                          : window.innerWidth - 56,

                      height:
                        mode === "presentation"
                          ? window.innerHeight
                          : mode === "full-screen"
                          ? window.innerHeight - 48
                          : window.innerHeight - 88,
                      sx: {
                        height:
                          mode === "presentation"
                            ? "100%"
                            : "calc(100vh - 88px)",
                        width:
                          mode === "normal" ? "calc(100vw - 56px)" : "100vw",
                        position: mode === "presentation" ? "initial" : "fixed",
                        display: mode === "presentation" ? "none" : "flex",
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                        left: mode === "normal" ? 56 : 0,
                        zIndex: 80,
                      },
                    }}
                  />
                </div>
              </Fragment>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

ViewDashboard.displayName = "ViewDashboard"

export default ViewDashboard
