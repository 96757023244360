import {Skeleton} from "@mui/material"
import {useEffect, useState, type FC} from "react"

type Props = {
  file?: string | File | Blob
  fileType?: string
  setIsViewerLoaded: React.Dispatch<React.SetStateAction<boolean>>
}

const mimeTypes = {
  mp4: "video/mp4",
  webm: "video/webm",
  ogg: "application/ogg",
  ogv: "application/ogg",
} as Record<string, string>

const VideoViewer: FC<Props> = (props) => {
  const {file, fileType, setIsViewerLoaded} = props

  const [url, setUrl] = useState("")

  useEffect(() => {
    if (!file) return
    if (typeof file === "string") {
      setUrl(file)
      setIsViewerLoaded(true)
    } else {
      const tempUrl = URL.createObjectURL(file)
      setUrl(tempUrl)
      setIsViewerLoaded(true)
    }
  }, [file])

  if (!file || !fileType || !url || !mimeTypes[fileType?.toLowerCase()])
    return (
      <Skeleton
        variant="rectangular"
        height="400"
        width="100%"
        sx={{borderRadius: "0.5rem"}}
      />
    )

  return (
    <video
      controls
      controlsList="nodownload"
      disablePictureInPicture
      height="400"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <source src={url} type={mimeTypes[fileType?.toLowerCase()]} />
    </video>
  )
}

VideoViewer.displayName = "VideoViewer"

export default VideoViewer
