import {type FC, useState, useEffect, useMemo, useRef} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query"
import {
  Skeleton,
  TableCell,
  TableRow,
  Typography,
  Stack,
  useTheme,
  Box,
  CircularProgress,
} from "@mui/material"
import {ReactSVG} from "react-svg"
import moment from "moment"
import type {
  ClientModel,
  DeliverableModel,
  FolderModel,
  PagedResult,
  ProjectAssignmentModel,
  Token,
} from "../../types"
import {
  projectsEndpoints,
  deliverableEndpoints,
  utilitiesEndpoints,
} from "../../services"
import {dateFormat, getDeliverableExtension, iconMap} from "../../helpers"
import {LeftPanelLayout} from "../../components/organisms/LeftPanelLayout/LeftPanelLayout"
import ProjectSummary from "../../components/organisms/LeftPanelLayout/Summary/ProjectSummary"
import {
  Breadcrumb,
  Table,
  TextWithTooltip,
  SearchBarr,
  TableHeadingProps,
  Tooltip,
  NewButton,
} from "../../components"
import AccessDenied from "../AccessDenied/AccessDenied"
import usePageStore from "../../stores/pageStore"
import {useInView} from "react-intersection-observer"
import {useConfigProvider} from "../../config"
import {useTranslation} from "react-i18next"
import {clickableTypes, contentTypeMap} from "../ViewFile/utils"
import {DownloadAltIcon} from "../../assets/icons"
import {FabricEvent} from "../../services"
import {appInsights} from "../../config/appInsights"
import {decodeToken} from "react-jwt"

const headings = [
  {name: "Name", field: "name", isSortable: true, sx: {width: "30%"}},
  {
    name: "Description",
    field: "description",
    isSortable: true,
    wide: true,
    sx: {width: "48%"},
  },
  {name: "Modified", field: "modified", isSortable: true, sx: {width: "10%"}},
  {
    name: "Created",
    field: "publishedDate",
    isSortable: true,
    sx: {width: "12%"},
  },
] satisfies TableHeadingProps[]

const limit = 17
const ViewFolder: FC = () => {
  const theme = useTheme()
  const {basename, token} = useConfigProvider()
  const decoded = decodeToken<Token>(token)
  const {getUserProjectById, getFolderById, getClientsByProjectId} =
    projectsEndpoints()
  const {getDeliverablesByFolderId, downloadDeliverableFile} =
    deliverableEndpoints()
  const {sendFabricEvent} = utilitiesEndpoints()

  const descriptionColumnRef = useRef<HTMLTableCellElement>(null)
  const [descriptionCharacterLimit, setDescriptionCharacterLimit] = useState(75)
  const {id, folderId} = useParams<{id: string; folderId: string}>()
  const [filters, setFilters] = useState<{searchText: string}>({searchText: ""})
  const [sortBy, setSortBy] = useState<"publishedDate" | "-publishedDate">(
    "-publishedDate",
  )
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [downloadingDeliverable, setDownloadingDeliverable] =
    useState<DeliverableModel | null>(null)

  const {ref, inView} = useInView()
  const {t} = useTranslation()
  const navigate = useNavigate()

  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["projectByUserId", id],
    queryFn: () => getUserProjectById(id || ""),
  })

  const folderQuery = useQuery<FolderModel>({
    queryKey: ["getFolder", id, folderId],
    queryFn: () => {
      return getFolderById(id || "", folderId || "")
    },
  })

  const queryString = useMemo(() => {
    return `query=${
      filters.searchText ? encodeURIComponent(filters.searchText) : ""
    }&sortBy=${sortBy}&limit=${limit}`
  }, [filters.searchText, sortBy])

  const lazyDeliverablesQuery = useInfiniteQuery<PagedResult<DeliverableModel>>(
    {
      initialPageParam: 1,
      queryKey: [
        "getDeliverablesByFolderId",
        folderId,
        filters.searchText,
        sortBy,
      ],
      queryFn: ({pageParam}) => {
        const page = parseInt(pageParam as string) || 1
        const offset = (page - 1) * limit
        const paginatedQuery = `${queryString}&offset=${offset}`
        return getDeliverablesByFolderId(folderId || "", paginatedQuery)
      },
      getNextPageParam: (lastPage) => {
        if ((lastPage?.currentPage || 1) * limit < (lastPage?.rowCount || 0)) {
          return lastPage.currentPage + 1
        }
        return undefined
      },
      placeholderData: keepPreviousData,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (
      filters.searchText &&
      (lazyDeliverablesQuery.isLoading ||
        (lazyDeliverablesQuery.isFetching &&
          !lazyDeliverablesQuery.isSuccess) ||
        lazyDeliverablesQuery.isRefetching)
    ) {
      setIsLoadingSearch(true)
    } else {
      setIsLoadingSearch(false)
    }
  }, [
    filters.searchText,
    lazyDeliverablesQuery.isLoading,
    lazyDeliverablesQuery.isFetching,
    lazyDeliverablesQuery.isSuccess,
    lazyDeliverablesQuery.isRefetching,
  ])

  useEffect(() => {
    if (inView && lazyDeliverablesQuery.hasNextPage) {
      lazyDeliverablesQuery.fetchNextPage()
    }
  }, [
    inView,
    lazyDeliverablesQuery.hasNextPage,
    lazyDeliverablesQuery.fetchNextPage,
    ref,
  ])

  useEffect(() => {
    const handleDescriptionColumnWidth = () => {
      if (descriptionColumnRef.current) {
        const descriptionColumnWidth = descriptionColumnRef.current.clientWidth
        const characterWidth = 8.7
        setDescriptionCharacterLimit(
          Math.floor(descriptionColumnWidth / characterWidth),
        )
      }
    }
    handleDescriptionColumnWidth()
    window.addEventListener("resize", handleDescriptionColumnWidth)
    return () => {
      window.removeEventListener("resize", handleDescriptionColumnWidth)
    }
  }, [descriptionColumnRef.current])

  const filteredData = useMemo(() => {
    const reducedDeliverables = lazyDeliverablesQuery?.data?.pages?.reduce(
      (acc, {results}) => [...acc, ...results],
      [] as DeliverableModel[],
    )
    return reducedDeliverables
  }, [lazyDeliverablesQuery.data])

  const clientQuery = useQuery<PagedResult<ClientModel>>({
    enabled:
      projectQuery.isFetched &&
      !projectQuery.isFetching &&
      !projectQuery.isLoading,
    queryKey: ["getClient", projectQuery.data?.id || ""],
    queryFn: () => {
      return getClientsByProjectId(id || "")
    },
    refetchOnWindowFocus: false,
  })

  const getDeliverableFileMutation = useMutation<File, Error, DeliverableModel>(
    {
      mutationKey: ["downloadFile", downloadingDeliverable?.id],
      mutationFn: async (deliverable) => {
        const deliverableName = deliverable?.deliverableName
        const fileName = deliverableName
          ? deliverableName
              .split("/")
              .pop()
              ?.match(/.{1,30}/g)
              ?.join("\n")
          : "defaultFileName"
        const extension = deliverable?.deliverableSource?.toLowerCase()
        const contentType = extension
          ? contentTypeMap[extension as keyof typeof contentTypeMap]
          : null
        const file = await downloadDeliverableFile(
          deliverable.id || "",
          fileName || "",
          contentType,
          extension || "",
        )
        return file || Promise.reject(new Error("File not found"))
      },
    },
  )

  const fabricEventMutation = useMutation<any, Error, FabricEvent>({
    mutationFn: (payload) => sendFabricEvent(payload),
    mutationKey: ["sendNotification"],
  })

  const handleNavigate = (deliverable: DeliverableModel) => {
    if (deliverable.deliverableType === "dashboard") {
      navigate(
        `${basename}/${id}/folders/${folderId}/dashboard/${deliverable.id}`,
      )
    } else if (deliverable.deliverableType === "web") {
      navigate(
        `${basename}/${id}/folders/${folderId}/web-report/${deliverable.id}`,
      )
    } else {
      navigate(`${basename}/${id}/folders/${folderId}/file/${deliverable.id}`)
    }
  }

  const handleDownload = async (deliverable: DeliverableModel) => {
    if (
      deliverable.restrictDownload ||
      !deliverable.deliverableUrl ||
      !deliverable
    ) {
      return
    }
    setDownloadingDeliverable(deliverable)

    try {
      const file = await getDeliverableFileMutation.mutateAsync(deliverable)
      if (file) {
        const fileUrl = URL.createObjectURL(file)
        const link = document.createElement("a")
        link.href = fileUrl
        link.download = `${deliverable.deliverableName || "file"}.${
          deliverable.deliverableSource || "pdf"
        }`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        appInsights.trackEvent({
          name: ["client uploads", "your uploads"].includes(
            folderQuery.data?.folderName.toLowerCase() || "",
          )
            ? "DOWNLOAD_FILE"
            : "DOWNLOAD_DELIVERABLE",
          properties: {
            deliverableId: deliverable.id,
            deliverableName: deliverable?.deliverableName,
            folderId: folderId,
            folderName: folderQuery?.data?.folderName,
            projectId: id,
            projectName:
              projectQuery.data?.projectAssignmentDisplayName ||
              projectQuery.data?.projectAssignmentName ||
              "",
            downloadedBy: decoded?.email || "",
          },
        })
        const fabricEventPayload = {
          type: ["client uploads", "your uploads"].includes(
            folderQuery.data?.folderName.toLowerCase() || "",
          )
            ? "download_file"
            : "download_deliverable",
          targetId: deliverable.id!,
          targetName: deliverable?.deliverableName,
        } satisfies FabricEvent
        await fabricEventMutation.mutateAsync(fabricEventPayload)
      }
    } catch (error) {
      console.error("Error downloading deliverable", error)
    }

    setDownloadingDeliverable(null)
  }

  const handleSearchChange = (searchText: string) => {
    setFilters((prev) => ({
      ...prev,
      searchText,
    }))
  }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage(
      projectQuery.data?.projectAssignmentDisplayName ||
        projectQuery.data?.projectAssignmentName ||
        "General",
    )
  }, [
    projectQuery.data?.projectAssignmentDisplayName,
    projectQuery.data?.projectAssignmentName,
  ])

  if (
    (!folderQuery.data?.isEnabled &&
      !folderQuery.isLoading &&
      !folderQuery.isFetching &&
      !folderQuery.isPending) ||
    (!projectQuery?.data?.enabled &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="folder"
        resourceName={folderQuery?.data?.folderName}
      />
    )
  }

  if (
    (!folderQuery.data &&
      !folderQuery.isLoading &&
      !folderQuery.isFetching &&
      !folderQuery.isPending) ||
    (!projectQuery.data &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending) ||
    (!clientQuery.data &&
      !clientQuery.isLoading &&
      !clientQuery.isFetching &&
      !clientQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="folder"
        resourceName={folderQuery?.data?.folderName}
      />
    )
  }

  return (
    <Stack flexGrow={1} flexDirection="row" component="section" height="100%">
      <LeftPanelLayout>
        <ProjectSummary projectQuery={projectQuery} />
      </LeftPanelLayout>
      <Stack
        paddingX={{xs: "1rem", md: "2rem", xl: "6rem"}}
        paddingY="1.5rem"
        gap="0.5rem"
        flexGrow={1}
        direction="column"
        component="main"
        marginLeft="236px"
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap="1rem"
        >
          <Breadcrumb
            links={[
              {
                label: t("Projects"),
                href: `${basename}`,
              },
              {
                label:
                  projectQuery?.data?.projectAssignmentDisplayName ||
                  projectQuery?.data?.projectAssignmentName ||
                  "",
                href: `${basename}/${projectQuery?.data?.id}`,
                loading: projectQuery?.isLoading,
              },
              {
                label: folderQuery?.data?.folderName || "",
                href: `${basename}/${projectQuery?.data?.id}/folders/${folderQuery?.data?.folderId}`,
                loading: folderQuery?.isLoading,
                active: true,
              },
            ]}
          />
          <Stack
            flexGrow={1}
            direction="row"
            maxWidth="466px"
            justifyContent="flex-end"
          >
            <SearchBarr
              size="small"
              state="collapsed"
              disabled={
                !lazyDeliverablesQuery?.isLoading &&
                (!filteredData ||
                  (filteredData?.length === 0 &&
                    filters.searchText.length === 0))
              }
              onChange={handleSearchChange}
              containerProps={{
                sx: {
                  width: "18.75rem",
                },
              }}
            />
          </Stack>
        </Stack>
        <Stack>
          {filters.searchText.length > 0 ? (
            <Stack direction="row" gap="0.5rem" alignItems="center">
              <Typography
                component="h1"
                fontSize="1rem"
                fontWeight="600"
                lineHeight="1.5rem"
                color="#242D35"
                margin="0"
              >
                {t("Search results")}
              </Typography>
              <Stack
                height="1.5rem"
                width="1.5rem"
                alignContent="center"
                justifyContent="center"
                borderRadius="100%"
                bgcolor="#BDBDBC"
                textAlign="center"
              >
                <Typography
                  fontSize="0.875rem"
                  lineHeight="1.25rem"
                  fontWeight="500"
                  color="#242D35"
                  textAlign="center"
                >
                  {!lazyDeliverablesQuery?.isLoading && !isLoadingSearch ? (
                    lazyDeliverablesQuery?.data?.pages?.at(0)?.results.length ||
                    0
                  ) : (
                    <Skeleton variant="rectangular" width="100%" height={15} />
                  )}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Typography
              component="h1"
              fontSize="1rem"
              fontWeight="600"
              lineHeight="1.5rem"
              color="#242D35"
              margin="0"
            >
              {t("Your deliverables")}
            </Typography>
          )}
        </Stack>
        <Box
          component="hr"
          sx={{
            border: 0,
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: "#BDBDBC",
          }}
        />
        <Stack height="calc(100% - 121px)">
          {!lazyDeliverablesQuery?.isLoading &&
          (!lazyDeliverablesQuery?.data || filteredData?.length === 0) &&
          filters.searchText.length === 0 ? (
            <Stack p={3} alignItems="center">
              <Typography color={theme.palette.info.dark}>
                {t("You have no deliverables allocated to you.")}
              </Typography>
            </Stack>
          ) : (
            <Table
              headings={headings}
              sortState={{sortBy, setSortBy}}
              filterState={{filters, setFilters}}
              style={{
                paddingTop: 0,
                height: "100%",
              }}
              tableProps={{
                stickyHeader: true,
              }}
              tableContainerProps={{
                sx: {
                  boxShadow: "none",
                  borderRadius: 0,
                  padding: "0",
                  boxSizing: "border-box",
                  maxHeight: "100%",
                  overflow: "auto",
                  backgroundColor: "transparent",
                  paddingRight:
                    (filteredData?.length || 0) > 12 ||
                    lazyDeliverablesQuery.isLoading ||
                    isLoadingSearch ||
                    (lazyDeliverablesQuery.isFetching &&
                      !lazyDeliverablesQuery.isFetchingNextPage)
                      ? "0.5rem"
                      : "0.25rem",

                  "&:hover": {
                    paddingRight: "0.25rem",
                  },
                },
              }}
            >
              {lazyDeliverablesQuery.isLoading ||
              isLoadingSearch ||
              (lazyDeliverablesQuery.isFetching &&
                !lazyDeliverablesQuery.isSuccess &&
                !lazyDeliverablesQuery.isFetchingNextPage)
                ? Array(10)
                    .fill(0)
                    .map((_, item) => (
                      <TableRow key={item} sx={{backgroundColor: "#FFFFFF"}}>
                        <TableCell
                          sx={{
                            width: "30%",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#1A1A1A",
                            lineHeight: "1.192rem",
                            padding: "0.75rem 1rem",
                            height: "2.5rem",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="374px"
                            height={15}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "45%",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#1A1A1A",
                            lineHeight: "1.192rem",
                            padding: "0.75rem 1rem",
                            height: "2.5rem",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "10%",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#1A1A1A",
                            lineHeight: "1.192rem",
                            padding: "0.75rem 1rem",
                            height: "2.5rem",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "15%",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#1A1A1A",
                            lineHeight: "1.192rem",
                            padding: "0.75rem 1rem",
                            height: "2.5rem",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                : lazyDeliverablesQuery.data &&
                  filteredData &&
                  filteredData?.map((deliverable, index) => (
                    <TableRow
                      key={deliverable.id}
                      ref={
                        index === (filteredData?.length || 0) - 2 &&
                        lazyDeliverablesQuery.data?.pages[0]?.rowCount &&
                        lazyDeliverablesQuery.data?.pages[0]?.rowCount > limit
                          ? ref
                          : null
                      }
                      sx={{
                        backgroundColor: "#FFFFFF",
                        transition: "all 0.2s ease-in-out",
                        cursor:
                          deliverable.deliverableType === "report" &&
                          !clickableTypes.includes(
                            deliverable.deliverableSource?.toLowerCase() || "",
                          )
                            ? "initial"
                            : "pointer",
                        "&:hover": {
                          backgroundColor:
                            deliverable.deliverableType === "report" &&
                            !clickableTypes.includes(
                              deliverable.deliverableSource?.toLowerCase() ||
                                "",
                            )
                              ? "#FFFFFF"
                              : "#EEEEEE",
                        },
                      }}
                      onClick={() => {
                        if (
                          deliverable.deliverableType === "report" &&
                          !clickableTypes.includes(
                            deliverable.deliverableSource?.toLowerCase() || "",
                          )
                        ) {
                          return
                        }
                        handleNavigate(deliverable)
                      }}
                    >
                      <TableCell
                        sx={{
                          width: "30%",
                          fontSize: "0.875rem",
                          fontWeight: 400,
                          color: "#1A1A1A",
                          lineHeight: "1.192rem",
                          padding: "0.75rem 1rem",
                          height: "2.5rem",

                          svg: {
                            maxHeight: "20px",
                          },
                        }}
                      >
                        <Stack flexDirection="row" gap={1}>
                          {deliverable.strictlyConfidential ? (
                            <Tooltip
                              arrow
                              title={t("Strictly confidential")}
                              placement="right"
                            >
                              <div>
                                <ReactSVG
                                  src={
                                    deliverable.deliverableType === "dashboard"
                                      ? "/icons/file-icons/Dashboard-confidential.svg"
                                      : deliverable.deliverableType === "web"
                                      ? "/icons/file-icons/Web-report-confidential.svg"
                                      : `/icons${
                                          iconMap[
                                            getDeliverableExtension(
                                              deliverable.deliverableUrl || "",
                                            )
                                          ]
                                        }-confidential.svg`
                                  }
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <ReactSVG
                              src={
                                deliverable.deliverableType === "dashboard"
                                  ? "/icons/file-icons/Dashboard.svg"
                                  : deliverable.deliverableType === "web"
                                  ? "/icons/file-icons/Web-report.svg"
                                  : `/icons${
                                      iconMap[
                                        getDeliverableExtension(
                                          deliverable.deliverableUrl || "",
                                        )
                                      ]
                                    }.svg`
                              }
                            />
                          )}
                          <Tooltip
                            arrow
                            placement="bottom-start"
                            title={t(
                              "Download this deliverable to view contents - no portal view available.",
                            )}
                            {...(deliverable.deliverableType === "report" &&
                            !clickableTypes.includes(
                              deliverable.deliverableSource?.toLowerCase() ||
                                "",
                            )
                              ? {}
                              : {
                                  open: false,
                                  onOpen: () => {},
                                  onClose: () => {},
                                })}
                            sx={{
                              "& .MuiTooltip-tooltip": {
                                maxWidth: "312px",
                                paddingX: "12px",
                                paddingY: "5px",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "22px",
                                textAlign: "left",
                              },
                              "& .MuiTooltip-arrow": {
                                left: "8px !important",
                                transform: "none !important",
                              },
                            }}
                          >
                            <Stack maxWidth="100%">
                              {deliverable.deliverableName.length >= 37 ? (
                                <TextWithTooltip
                                  text={deliverable.deliverableName}
                                  tooltipProps={{
                                    arrow: false,
                                    placement: "bottom-start",
                                    sx: {
                                      "& .MuiTooltip-tooltip": {
                                        backgroundColor: "#FFFFFF",
                                        height: "26px",
                                        maxWidth: "100vw",
                                        color: "#242D35",
                                        textAlign: "left",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        fontWeight: "400",
                                        paddingX: "8px",
                                        paddingY: "2px",
                                        borderWidth: "0.5px",
                                        borderStyle: "solid",
                                        borderColor: "#1B1B1B",
                                        borderRadius: "0px",
                                        marginTop:
                                          deliverable.deliverableType ===
                                            "report" &&
                                          !clickableTypes.includes(
                                            deliverable.deliverableSource?.toLowerCase() ||
                                              "",
                                          )
                                            ? "78px !important"
                                            : undefined,
                                      },
                                    },
                                  }}
                                  textStyle={{
                                    fontSize: "0.875rem",
                                    fontWeight: 400,
                                    color: "#1A1A1A",
                                    lineHeight: "1.192rem",
                                  }}
                                  limit={37}
                                />
                              ) : (
                                deliverable.deliverableName
                              )}
                            </Stack>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                      <TableCell
                        ref={index === 0 ? descriptionColumnRef : null}
                        sx={{
                          width: "48%",
                          fontSize: "0.875rem",
                          fontWeight: 400,
                          color: "#1A1A1A",
                          lineHeight: "1.192rem",
                          padding: "0.75rem 1rem",
                          height: "2.5rem",
                        }}
                      >
                        {deliverable.deliverableDescription.length >= 75 ? (
                          <TextWithTooltip
                            tooltipProps={{
                              placement: "bottom-start",
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "#FFFFFF",
                                  height: "26px",
                                  maxWidth: "100vw",
                                  color: "#242D35",
                                  textAlign: "left",
                                  fontSize: "14px",
                                  lineHeight: "22px",
                                  fontWeight: "400",
                                  paddingX: "8px",
                                  paddingY: "2px",
                                  borderWidth: "0.5px",
                                  borderStyle: "solid",
                                  borderColor: "#1B1B1B",
                                  borderRadius: "0px",
                                },
                              },
                            }}
                            text={deliverable.deliverableDescription}
                            limit={descriptionCharacterLimit}
                          />
                        ) : (
                          deliverable.deliverableDescription
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "10%",
                          fontSize: "0.875rem",
                          fontWeight: 400,
                          color: "#1A1A1A",
                          lineHeight: "1.192rem",
                          padding: "0.75rem 1rem",
                          height: "2.5rem",
                        }}
                      >
                        {!deliverable.lastUpdateTimestamp
                          ? ""
                          : moment(deliverable.lastUpdateTimestamp).format(
                              dateFormat,
                            )}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "12%",
                          fontSize: "0.875rem",
                          fontWeight: 400,
                          color: "#1A1A1A",
                          lineHeight: "1.192rem",
                          height: "2.5rem",
                          paddingY: "0",
                          paddingRight: "0",
                          paddingLeft: "16px",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="16px"
                          flexWrap="nowrap"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            height="40px"
                            minWidth="74px"
                          >
                            <Typography
                              fontSize="0.875rem"
                              fontWeight={400}
                              color="#1A1A1A"
                              lineHeight="1.192rem"
                            >
                              {moment(deliverable.createdTimestamp).format(
                                dateFormat,
                              ) || ""}
                            </Typography>
                          </Stack>
                          <Tooltip
                            arrow
                            placement="bottom-start"
                            title={
                              deliverable.restrictDownload ||
                              !deliverable.deliverableUrl ||
                              deliverable.deliverableType === "dashboard"
                                ? t("Unavailable")
                                : downloadingDeliverable?.id === deliverable.id
                                ? t("Downloading")
                                : t("Download")
                            }
                            sx={{
                              "& .MuiTooltip-arrow": {
                                left: "10px !important",
                                transform: "none !important",
                              },
                            }}
                          >
                            <Box>
                              <NewButton
                                icon
                                size="medium"
                                variant="text"
                                IconLeft={
                                  downloadingDeliverable?.id ===
                                  deliverable.id ? (
                                    <CircularProgress
                                      size="14px"
                                      sx={{
                                        color: "#053747",
                                      }}
                                    />
                                  ) : (
                                    <DownloadAltIcon
                                      width="14px"
                                      height="14px"
                                    />
                                  )
                                }
                                onClick={
                                  deliverable.restrictDownload ||
                                  !deliverable.deliverableUrl ||
                                  deliverable.deliverableType === "dashboard" ||
                                  downloadingDeliverable?.id === deliverable.id
                                    ? (e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                      }
                                    : (e) => {
                                        e.stopPropagation()
                                        handleDownload(deliverable)
                                      }
                                }
                                disabled={
                                  deliverable.restrictDownload ||
                                  !deliverable.deliverableUrl ||
                                  deliverable.deliverableType === "dashboard"
                                }
                                sx={{
                                  minWidth: "unset",
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "4px",
                                  padding: "0",
                                  "&:hover": {
                                    backgroundColor:
                                      deliverable.restrictDownload ||
                                      !deliverable.deliverableUrl ||
                                      deliverable.deliverableType ===
                                        "dashboard"
                                        ? "transparent"
                                        : "#dedede",
                                  },
                                  "&:active": {
                                    backgroundColor:
                                      deliverable.restrictDownload ||
                                      !deliverable.deliverableUrl ||
                                      deliverable.deliverableType ===
                                        "dashboard"
                                        ? "transparent"
                                        : "#cdcdcd",
                                  },
                                  svg: {
                                    color: "inherit",
                                    width: "14px",
                                    height: "14px",
                                    "g, path": {
                                      fill:
                                        deliverable.restrictDownload ||
                                        !deliverable.deliverableUrl ||
                                        deliverable.deliverableType ===
                                          "dashboard"
                                          ? "#8b8b8a"
                                          : "#053747",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
              {lazyDeliverablesQuery.isFetchingNextPage ? (
                <TableRow sx={{backgroundColor: "#FFFFFF"}}>
                  <TableCell
                    colSpan={4}
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: 400,
                      color: "#1A1A1A",
                      lineHeight: "1.192rem",
                      padding: "0.75rem 1rem",
                      height: "2.5rem",
                    }}
                  >
                    <Skeleton variant="rectangular" width="100%" height={15} />
                  </TableCell>
                </TableRow>
              ) : null}
            </Table>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ViewFolder
