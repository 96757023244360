import {CSSProperties, FC, Fragment, useState} from "react"
// @ts-ignore
import {Helmet} from "react-helmet"
// @ts-ignore
import Viz from "./Viz"

type Props = {
  src?: string
  height?: number | string
  width?: number | string
  hideTabs?: boolean
  device?: string
  toolbar?: string
  token?: string
  articleStyle?: CSSProperties
}

const TableauEmbed: FC<Props> = (props) => {
  const {src, height = 0, width, hideTabs, device, token, articleStyle} = props
  const [vizObj, setVizObj] = useState(undefined)
  const [interactive, setInteractive] = useState(false)

  const _articleStyle = {
    height: "100%",
    width: "100%",
  }

  return (
    <Fragment>
      <Helmet>
        <script
          type="module"
          src="https://uk-tableau.crlit.com/javascripts/api/tableau.embedding.3.latest.min.js"
          async
        ></script>
      </Helmet>

      <article className="vizArticle" style={articleStyle}>
        <Viz
          vizObj={vizObj}
          setVizObj={setVizObj}
          interactive={interactive}
          setInteractive={setInteractive}
          articleStyle={articleStyle || _articleStyle}
          vizUrl={src}
          height={height}
          width={width}
          hideTabs={hideTabs}
          device={device}
          token={token}
        />
      </article>
    </Fragment>
  )
}

TableauEmbed.displayName = "TableauEmbed"

export default TableauEmbed
