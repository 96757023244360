import {
  type FC,
  Fragment,
  type PropsWithChildren,
  useState,
  useEffect,
} from "react"
import {UseQueryResult, useQuery} from "@tanstack/react-query"
import {useNavigate, useParams} from "react-router-dom"
import {Avatar, Box, Divider, Skeleton, Stack, Typography} from "@mui/material"
import {ReactSVG} from "react-svg"
import moment from "moment"
import {dateFormat, truncateText} from "../../../../helpers"
import AccessListModal from "./AccessListModal"
import {Tooltip} from "../../../atoms"
import {TextWithTooltip, UserAccessButton} from "../../../molecules"
import type {
  DeliverableModel,
  FolderModel,
  PagedResult,
  ProjectAssignmentModel,
  ProjectManager,
  UserModel,
} from "../../../../types"
import {MinusAltIcon, PlusAltIcon} from "../../../../assets/icons"
import {projectsEndpoints} from "../../../../services"
import {Regions} from "../../../../types/DataRegions"
import {useConfigProvider} from "../../../../config"
import {stringAvatar} from "../../../../helpers/avatarFromString"
import {projectOwnerNames} from "../../../../helpers/projectOwnerNames"
import ProjectQuestionModal from "../../ProjectQuestionModal/ProjectQuestionModal"
import {useTranslation} from "react-i18next"

type FolderRowProps = {
  onClick: () => void
  active: boolean
}

type ChipProps = {
  text: string
  index: number
}

const Chip: FC<ChipProps> = (props) => {
  const {text, index} = props
  return <Avatar {...stringAvatar(text, index)} />
}

const FolderRow: FC<PropsWithChildren<FolderRowProps>> = (props) => {
  const {onClick, active, children} = props
  return (
    <Stack
      onClick={onClick}
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      sx={{
        height: "1.5rem",
        paddingLeft: "0.5rem",
        borderLeft: `4px solid ${active ? "#053747" : "#DEDEDE"}`,
        "&:hover": {
          borderLeft: `4px solid ${active ? "#053747" : "#7E8081"}`,
          backgroundColor: "#F5F7F8",
          cursor: "pointer",
        },
      }}
    >
      {children}
    </Stack>
  )
}

type ProjectSummaryProps = {
  projectQuery: UseQueryResult<ProjectAssignmentModel, Error>
  yourUploadsFolderQuery?: UseQueryResult<PagedResult<DeliverableModel>>
}

const ProjectSummary: FC<ProjectSummaryProps> = (props) => {
  const {projectQuery} = props
  const {id, folderId} = useParams<{id: string; folderId: string}>()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {basename, serverUrl} = useConfigProvider()
  const [expandedDescription, setExpandedDescription] = useState(false)
  const [openAccessModal, setOpenAccessModal] = useState(false)
  const [openProjectQuestionModal, setOpenProjectQuestionModal] =
    useState(false)
  const [selectedProject, setSelectedProject] = useState<
    ProjectAssignmentModel | undefined
  >(undefined)

  const {
    getFoldersByProjectId,
    getProjectUserClients,
    getAvailableRegions,
    getProjectManager,
  } = projectsEndpoints()

  const projectManagerQuery = useQuery<ProjectManager>({
    queryKey: ["getProjectManagerName", id],
    queryFn: () => getProjectManager(serverUrl, id || ""),
    enabled: !!id,
    refetchOnWindowFocus: false,
  })

  const foldersQuery = useQuery<PagedResult<FolderModel>>({
    queryKey: ["getFolders", id],
    queryFn: () => {
      return getFoldersByProjectId(id || "", "limit=1000")
    },
  })

  const availableRegionsQuery = useQuery<Regions>({
    queryKey: ["getAvailableRegions", id],
    queryFn: () => getAvailableRegions(),
  })

  const projectUserClientsQuery = useQuery<UserModel[]>({
    queryKey: ["projectUserClients", id],
    queryFn: () =>
      getProjectUserClients(
        id ? id : "",
        projectQuery?.data?.subscriptionProjectAssignment
          ? projectQuery?.data?.subscriptionProjectAssignment
          : false,
      ),
    enabled: projectQuery.isSuccess && !!projectQuery.data,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    setSelectedProject(projectQuery.data)
  }, [projectQuery.data])

  const handleToggleDescription = () => {
    setExpandedDescription((e) => !e)
  }

  return (
    <Fragment>
      {/* Project name */}
      {!projectQuery.isLoading ? (
        <TextWithTooltip
          text={projectQuery.data?.projectAssignmentDisplayName || ""}
          limit={15}
          tooltipProps={{
            placement: "bottom-start",
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: "#FFFFFF",
                height: "26px",
                maxWidth: "100vw",
                color: "#242D35",
                textAlign: "left",
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: "400",
                paddingX: "8px",
                paddingY: "2px",
                borderWidth: "0.5px",
                borderStyle: "solid",
                borderColor: "#1B1B1B",
                borderRadius: "0px",
              },
            },
          }}
          textStyle={{
            fontSize: "1.25rem",
            lineHeight: "1.5rem",
            fontWeight: "600",
            color: "#003D50",
            display: "block",
            width: "fit-content",
          }}
        />
      ) : (
        <Skeleton />
      )}
      <Stack direction="column" gap="1rem" component="section">
        {/*Contents counts*/}
        <Box
          component="article"
          id="content-counts"
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          rowGap="0.5rem"
        >
          <Stack direction="row" alignItems="center">
            <Tooltip title={t("Folders")} arrow placement="right">
              <Stack
                direction="row"
                gap="0.25rem"
                alignItems="center"
                sx={{"div > div": {height: "20px"}}}
              >
                <ReactSVG src="/icons/folder.svg" />
                {!projectQuery.isLoading ? (
                  <Typography
                    fontSize="0.875rem"
                    lineHeight="1.25rem"
                    fontWeight="400"
                    color="#8B8B8A"
                  >
                    {projectQuery.data?.foldersCount || 0}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width="1rem" />
                )}
              </Stack>
            </Tooltip>
          </Stack>
          <Stack direction="row">
            <Tooltip title={t("Dashboards")} arrow placement="right">
              <Stack
                direction="row"
                gap="0.25rem"
                alignItems="center"
                sx={{"div > div": {height: "20px"}}}
              >
                <ReactSVG src="/icons/dashboard.svg" />
                {!projectQuery.isLoading ? (
                  <Typography
                    fontSize="0.875rem"
                    lineHeight="1.25rem"
                    fontWeight="400"
                    color="#8B8B8A"
                  >
                    {projectQuery.data?.dashboardsCount || 0}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width="1rem" />
                )}
              </Stack>
            </Tooltip>
          </Stack>
          <Stack direction="row">
            <Tooltip title={t("Web reports")} arrow placement="right">
              <Stack
                direction="row"
                gap="0.25rem"
                alignItems="center"
                sx={{"div > div": {height: "20px"}}}
              >
                <ReactSVG src="/icons/webReport.svg" />
                {!projectQuery.isLoading ? (
                  <Typography
                    fontSize="0.875rem"
                    lineHeight="1.25rem"
                    fontWeight="400"
                    color="#8B8B8A"
                  >
                    {projectQuery.data?.webReportsCount || 0}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width="1rem" />
                )}
              </Stack>
            </Tooltip>
          </Stack>
          <Stack direction="row">
            <Tooltip title={t("Files")} arrow placement="right">
              <Stack
                direction="row"
                gap="0.25rem"
                alignItems="center"
                sx={{"div > div": {height: "20px"}}}
              >
                <ReactSVG src="/icons/clip.svg" />
                {!projectQuery.isLoading ? (
                  <Typography
                    fontSize="0.875rem"
                    lineHeight="1.25rem"
                    fontWeight="400"
                    color="#8B8B8A"
                  >
                    {projectQuery.data?.filesCount || 0}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width="1rem" />
                )}
              </Stack>
            </Tooltip>
          </Stack>
          {!projectQuery.data?.subscriptionProjectAssignment ? (
            <Stack direction="row">
              <Tooltip title={t("Your uploads")} arrow placement="right">
                <Stack
                  direction="row"
                  gap="0.25rem"
                  alignItems="center"
                  sx={{"div > div": {height: "20px"}}}
                >
                  <ReactSVG src="/icons/upload.svg" />
                  {!projectQuery.isLoading ? (
                    <Typography
                      fontSize="0.875rem"
                      lineHeight="1.25rem"
                      fontWeight="400"
                      color="#8B8B8A"
                    >
                      {projectQuery.data?.uploadsCount || 0}
                    </Typography>
                  ) : (
                    <Skeleton variant="text" width="1rem" />
                  )}
                </Stack>
              </Tooltip>
            </Stack>
          ) : null}
        </Box>
        {/* Folders list */}
        {!foldersQuery.isLoading ? (
          <Stack
            direction="column"
            gap="0.125rem"
            sx={{
              maxHeight: "99px",
              overflow: "auto",
              paddingRight:
                (foldersQuery.data?.results?.length || 0) > 4
                  ? "0.5rem"
                  : "0.25rem",

              "&:hover": {
                paddingRight: "0.25rem",
              },
            }}
          >
            {foldersQuery.data?.results &&
              foldersQuery.data?.results
                ?.filter(
                  (f) =>
                    f.folderName.toLowerCase() !== "client uploads" &&
                    f.folderName.toLowerCase() !== "clientuploads",
                )
                .map((folder) => (
                  <FolderRow
                    key={folder.folderId}
                    active={folder.folderId === folderId}
                    onClick={() =>
                      navigate(
                        `${basename}/${id || ""}/folders/${folder.folderId}`,
                      )
                    }
                  >
                    <Stack
                      direction="row"
                      height="1.25rem"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{
                        "span > span": {
                          height: "1rem",
                          width: "1rem",

                          svg: {
                            height: "1rem",
                            width: "1rem",
                            path: {
                              fill:
                                folder.folderId === folderId
                                  ? "#053747"
                                  : undefined,
                            },
                          },
                        },
                      }}
                    >
                      <ReactSVG
                        src={`${
                          folder.folderId === folderId
                            ? "/icons/folder-selected.svg"
                            : "/icons/folder.svg"
                        }`}
                        wrapper="span"
                        height="1rem"
                        width="1rem"
                        className="icon-wrapper"
                      />
                      <TextWithTooltip
                        text={folder.folderName}
                        limit={13}
                        includeEllipsis={false}
                        textStyle={{
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          color: "#242D35",
                          fontWeight: "400",
                        }}
                        tooltipProps={{
                          placement: "bottom-start",
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "#FFFFFF",
                              height: "26px",
                              maxWidth: "100vw",
                              color: "#242D35",
                              textAlign: "left",
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontWeight: "400",
                              paddingX: "8px",
                              paddingY: "2px",
                              borderWidth: "0.5px",
                              borderStyle: "solid",
                              borderColor: "#1B1B1B",
                              borderRadius: "0px",
                            },
                          },
                        }}
                      />
                    </Stack>
                    <Typography
                      fontSize="0.875rem"
                      lineHeight="1.25rem"
                      color="#7E8081"
                    >
                      (
                      {(folder.totalFilesDeliverables || 0) +
                        (folder.totalUrlDeliverables || 0) +
                        (folder.totalWebReportDeliverables || 0)}
                      )
                    </Typography>
                  </FolderRow>
                ))}
          </Stack>
        ) : (
          <Stack spacing={0.5}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                paddingLeft: 1,
                borderLeft: "4px solid #CACDCD",
              }}
            >
              <Box
                display="flex"
                height={20}
                alignItems="center"
                padding="1rem 0"
                gap={1}
              >
                <ReactSVG
                  src="/icons/folder.svg"
                  wrapper="span"
                  className="icon-wrapper"
                />
                <Skeleton width={130} />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                padding: "1rem, 0",
                paddingLeft: 1,
                borderLeft: "4px solid #CACDCD",
              }}
            >
              <Box
                display="flex"
                height={20}
                alignItems="center"
                padding="1rem 0"
                gap={1}
              >
                <ReactSVG
                  src="/icons/folder.svg"
                  wrapper="span"
                  className="icon-wrapper"
                />
                <Skeleton width={130} />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                padding: "1rem, 0",
                paddingLeft: 1,
                borderLeft: "4px solid #CACDCD",
              }}
            >
              <Box
                display="flex"
                height={20}
                alignItems="center"
                padding="1rem 0"
                gap={1}
              >
                <ReactSVG
                  src="/icons/folder.svg"
                  wrapper="span"
                  className="icon-wrapper"
                />
                <Skeleton width={130} />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                padding: "1rem, 0",
                paddingLeft: 1,
                borderLeft: "4px solid #CACDCD",
              }}
            >
              <Box
                display="flex"
                height={20}
                alignItems="center"
                padding={"1rem 0"}
                gap={1}
              >
                <ReactSVG
                  src="/icons/folder.svg"
                  wrapper="span"
                  className="icon-wrapper"
                />
                <Skeleton width={130} />
              </Box>
            </Box>
          </Stack>
        )}
        {/* Project description */}
        <Stack direction="column" gap="0.25rem" component="section">
          <Stack
            direction="row"
            gap="0.5rem"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              fontSize="0.875rem"
              lineHeight="1.25rem"
              color="#242D35"
            >
              {t("Project description")}
            </Typography>
            {!projectQuery.isLoading &&
            (projectQuery.data?.projectAssignmentDescription?.length || 0) >
              220 ? (
              <Box
                onClick={handleToggleDescription}
                sx={{cursor: "pointer"}}
                height="1.125rem"
                width="1.125rem"
              >
                {expandedDescription ? <MinusAltIcon /> : <PlusAltIcon />}
              </Box>
            ) : null}
          </Stack>
          {!projectQuery.isLoading ? (
            <Typography
              fontSize="0.75rem"
              lineHeight="1rem"
              color="#4F566B"
              component="p"
              sx={{
                wordBreak: "break-word",
                whiteSpace: "pre-line",
                fontSize: "0.75rem",
                lineHeight: "1rem",
                color: "#4F566B",
              }}
            >
              {expandedDescription
                ? projectQuery.data?.projectAssignmentDescription
                : truncateText(
                    projectQuery.data?.projectAssignmentDescription || "",
                    220,
                  )}
            </Typography>
          ) : (
            <Skeleton variant="text" width="100%" />
          )}
        </Stack>
        {/* Project team */}
        <Stack gap="8px">
          <Typography fontSize="0.875rem" lineHeight="1.25rem" color="#242D35">
            {t("Project team")}
          </Typography>
          <Stack direction="row" gap="10px">
            {projectManagerQuery.data?.projectManagerName !==
              "Not applicable" &&
            projectManagerQuery.data?.projectManagerName?.length ? (
              <Stack direction="row" gap="10px">
                <Tooltip
                  title={projectManagerQuery.data?.projectManagerName}
                  placement="bottom"
                  arrow
                >
                  <Stack
                    onClick={() => setOpenProjectQuestionModal(true)}
                    direction="row"
                    sx={{
                      "&: hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Chip
                      aria-label="project-team"
                      text={projectManagerQuery.data?.projectManagerName}
                      index={0}
                    />
                  </Stack>
                </Tooltip>
                <Divider orientation="vertical" />
              </Stack>
            ) : null}
            {projectQuery.data?.projectAssignmentOwners?.length &&
            projectQuery.data?.projectAssignmentOwners?.length > 4 ? (
              <Fragment>
                <Tooltip
                  title={projectOwnerNames(projectQuery)}
                  placement="right"
                >
                  <Stack direction="row" gap="0.5rem" alignItems="center">
                    <Stack direction="row" gap="0.5rem">
                      {projectQuery.data?.projectAssignmentOwners
                        .slice(0, 4)
                        .map(
                          (owner, i) =>
                            `${owner.firstname} ${owner.surname}` !==
                              projectManagerQuery.data?.projectManagerName && (
                              <Chip
                                key={owner?.userId}
                                index={i}
                                text={owner.firstname + " " + owner.surname}
                              />
                            ),
                        )}
                    </Stack>
                    <Typography
                      fontWeight="400"
                      fontSize="0.875rem"
                      lineHeight="1.25rem"
                      color="#226CA6"
                      sx={{
                        textDecoration: "underline",
                        textDecorationColor: "#226CA6",
                        cursor: "default",
                      }}
                    >
                      +{projectQuery.data?.projectAssignmentOwners.length - 4}
                    </Typography>
                  </Stack>
                </Tooltip>
              </Fragment>
            ) : (
              projectQuery.data?.projectAssignmentOwners?.map(
                (owner, i) =>
                  `${owner.firstname} ${owner.surname}` !==
                    projectManagerQuery.data?.projectManagerName && (
                    <Tooltip
                      title={owner.firstname + " " + owner.surname}
                      key={`ownwer-${i}`}
                      arrow
                      placement="bottom"
                    >
                      <div>
                        <Chip
                          index={i}
                          key={owner?.userId}
                          text={owner.firstname + " " + owner.surname}
                        />
                      </div>
                    </Tooltip>
                  ),
              )
            )}
          </Stack>
        </Stack>
        {/* Expiry date */}
        <Stack direction="column" gap="0.25rem" component="section">
          <Typography fontSize="0.875rem" lineHeight="1.25rem" color="#242D35">
            {t("Expiry date")}
          </Typography>
          {!projectQuery.isLoading ? (
            <Typography fontSize="0.75rem" lineHeight="1rem" color="#4F566B">
              {moment(projectQuery.data?.expiryDate).format(dateFormat)}
            </Typography>
          ) : (
            <Skeleton variant="text" width="100%" />
          )}
        </Stack>
        {/* Data Region */}
        <Stack direction="column" gap="0.25rem" component="section">
          <Typography fontSize="0.875rem" lineHeight="1.25rem" color="#242D35">
            {t("Data region")}
          </Typography>
          {!projectQuery.isLoading && !availableRegionsQuery.isLoading ? (
            <Typography fontSize="0.75rem" lineHeight="1rem" color="#4F566B">
              {t(
                availableRegionsQuery.data?.regions?.find(
                  (el) =>
                    el.code.toLowerCase() ===
                    projectQuery.data?.projectDefaultStorageRegion?.toLowerCase(),
                )?.name || "Western Europe",
              )}
            </Typography>
          ) : (
            <Skeleton variant="text" width="100%" />
          )}
        </Stack>
        {/* Access list */}
        <UserAccessButton
          disabled={projectQuery.isLoading || projectUserClientsQuery.isLoading}
          openAccessModal={openAccessModal}
          setOpenAccessModal={setOpenAccessModal}
        />
      </Stack>
      <AccessListModal
        openAccessModal={openAccessModal ? openAccessModal : false}
        setOpenAccessModal={setOpenAccessModal}
        isLoading={projectUserClientsQuery.isLoading || projectQuery.isLoading}
        usersToList={projectUserClientsQuery.data || []}
      />
      <ProjectQuestionModal
        open={openProjectQuestionModal}
        selectedProject={selectedProject}
        projectManager={projectManagerQuery.data}
        onClose={() => setOpenProjectQuestionModal(false)}
      />
    </Fragment>
  )
}

export default ProjectSummary
