export const contentTypeMap = {
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword",
  dot: "application/msword",
  dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  docm: "application/vnd.ms-word.document.macroEnabled.12",
  dotm: "application/vnd.ms-word.template.macroEnabled.12",
  rvt: "application/vnd.autodesk.autocad",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls: "application/vnd.ms-excel",
  xlt: "application/vnd.ms-excel",
  xlsm: "application/vnd.ms-excel.sheet.macroEnabled.12",
  xlsb: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  xltm: "application/vnd.ms-excel.template.macroEnabled.12",
  xltx: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ppt: "application/vnd.ms-powerpoint",
  pps: "application/vnd.ms-powerpoint",
  pptm: "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  potm: "application/vnd.ms-powerpoint.template.macroEnabled.12",
  potx: "application/vnd.openxmlformats-officedocument.presentationml.template",
  ppsx: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  ppsm: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  pdf: "application/pdf",
  fdf: "application/pdf",
  xfdf: "application/pdf",
  rtf: "application/rtf",
  eml: "message/rfc822",
  msg: "application/vnd.ms-outlook",
  pub: "application/x-mspublisher",
  vsdx: "application/vnd.visio",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  jfif: "image/jpeg",
  png: "image/png",
  tif: "image/tiff",
  tiff: "image/tiff",
  gif: "image/gif",
  bmp: "image/bmp",
  md: "text/markdown",
  csv: "text/csv",
  dwg: "application/acad",
  dxf: "application/dxf",
  dwf: "model/vnd.dwf",
  dgn: "image/vnd.dgn",
  zip: "application/zip",
  txt: "text/plain",
  mp4: "video/mp4",
  webm: "video/webm",
  ogg: "video/ogg",
  ogv: "video/ogg",
}

export const previewableTypes = [
  "docx",
  "dotx",
  "docm",
  "dotm",
  "pdf",
  "pptx",
  "pptm",
  "xlsx",
  "xlsm",
  "jpg",
  "jpeg",
  "jfif",
  "png",
  "tif",
  "tiff",
  "bmp",
  "gif",
  "md",
  "mp4",
  "webm",
  "ogg",
  "ogv",
]

export const videoTypes = ["mp4", "webm", "ogg", "ogv"]

export const clickableTypes = [...previewableTypes, ...videoTypes]