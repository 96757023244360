import {type FC} from "react"
import {type SxProps, TooltipProps, Typography} from "@mui/material"
import {Tooltip} from "../../atoms"
import {truncateText} from "../../../helpers/truncateText"

type Props = {
  text: string
  limit: number
  tooltipStyle?: SxProps
  textStyle?: SxProps
  tooltipProps?: Omit<TooltipProps, "title" | "children">
  includeEllipsis?: boolean
  suffix?: string
}

const TextWithTooltip: FC<Props> = (props) => {
  const {
    text,
    limit,
    tooltipStyle,
    textStyle,
    tooltipProps,
    includeEllipsis = true,
    suffix,
  } = props

  if (text.length > limit) {
    return (
      <Tooltip title={text} sx={tooltipStyle} {...tooltipProps}>
        <Typography sx={textStyle}>
          {truncateText(text, limit, "...", includeEllipsis)}&nbsp;{suffix}
        </Typography>
      </Tooltip>
    )
  }

  return <Typography sx={textStyle}>{text}</Typography>
}

export default TextWithTooltip
