import type {FC, ReactNode} from "react"
import {Skeleton, Stack} from "@mui/material"
import {ProjectListCard} from "./ProjectListCard"
import type {ProjectAssignmentModel} from "../../../types"

type Props = {
  projects: ProjectAssignmentModel[]
  isLoading?: boolean
  isFetching?: boolean
  LazyLoadingIndicator?: ReactNode
}

export const ProjectListView: FC<Props> = (props) => {
  const {projects, isLoading, isFetching, LazyLoadingIndicator} = props
  if (isLoading)
    return (
      <Stack gap="0.25rem">
        {[1, 2, 3, 4, 5].map((item) => (
          <Skeleton
            key={item}
            variant="rectangular"
            width="100%"
            height="123px"
            sx={{borderRadius: "0.5rem 0.5rem 0 0"}}
          />
        ))}
      </Stack>
    )
  return (
    <Stack gap="0.25rem">
      {projects.map((project) => (
        <ProjectListCard
          key={project.id}
          projectId={project.id}
          projectName={project.projectAssignmentDisplayName || ""}
          projectDescription={project.projectAssignmentDescription}
          createdAt={project.createdTimestamp}
          foldersCount={project.folder?.length}
          filesCount={project.filesCount}
          dashboardsCount={project.dashboardsCount}
          webReportsCount={project.webReportsCount}
          strictlyConfidential={project.strictlyConfidential}
        />
      ))}
      {isFetching
        ? [1, 2, 3, 4, 5].map((item) => (
            <Skeleton
              key={item}
              variant="rectangular"
              width="100%"
              height="123px"
              sx={{borderRadius: "0.5rem 0.5rem 0 0"}}
            />
          ))
        : null}
      {LazyLoadingIndicator}
    </Stack>
  )
}

ProjectListView.displayName = "ProjectListView"

export default ProjectListView
