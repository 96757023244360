import {Stack, Typography} from "@mui/material"
import {
  ChevronLeftIcon,
  CloseAltIcon,
  CloseFullScreenModeIcon,
  DownloadAltIcon,
  FullScreenModeAltIcon,
  PresentationModeAltIcon,
} from "../../../assets/icons"
import {NewButton, Tooltip} from "../../../components/atoms"
import {
  type DetailedHTMLProps,
  forwardRef,
  type HTMLAttributes,
  MutableRefObject,
  useState,
} from "react"

type Props = {
  mode: "normal" | "full-screen" | "presentation"
  loading?: boolean
  download?: boolean
  presentationMode?: boolean
  fullScreenMode?: boolean
  containerProps?: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
  handleClose: () => void
  handleDownload?: () => void
  handlePresentationMode?: () => void
  handleFullScreenMode?: () => void
}

const RIGHT_PADDING = 16
const BUTTON_WIDTH = 40
const GAP = 8
const SEP_WIDTH = 1

const FloatingToolbar = forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    mode,
    loading,
    download,
    fullScreenMode,
    presentationMode,
    containerProps,
    handleClose,
    handleDownload,
    handlePresentationMode,
    handleFullScreenMode,
  } = props

  const [expanded, setExpanded] = useState(true)
  const parentRef = ref as MutableRefObject<HTMLElement> | null

  const handleToggle = () => {
    setExpanded((prev) => !prev)
  }

  const collapseTranslate =
    RIGHT_PADDING +
    BUTTON_WIDTH +
    (download ? BUTTON_WIDTH : 0) +
    (fullScreenMode ? BUTTON_WIDTH : 0) +
    (presentationMode ? BUTTON_WIDTH : 0) +
    (fullScreenMode || presentationMode || download
      ? SEP_WIDTH + GAP + GAP - 10
      : 0)

  return (
    <header
      id="floating-toolbar"
      {...containerProps}
      style={{
        position: "fixed",
        left: 56,
        top: 88,
        width: "calc(100vw - 56px)",
        zIndex: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        ...containerProps?.style,
      }}
    >
      <Stack
        component="nav"
        direction="row"
        alignItems="center"
        paddingX="16px"
        gap="8px"
        sx={{
          backgroundColor: "#414141e6",
          borderBottomLeftRadius: "4px",
          transform: expanded
            ? "translateX(0)"
            : `translateX(${collapseTranslate}px)`,
          transition: "transform 0.3s ease-in-out",
        }}
      >
        {loading ? null : (
          <Stack component="section" direction="row" alignItems="center">
            <Tooltip
              title={expanded ? "Close toolbar" : "Open toolbar"}
              placement="bottom"
              arrow
              slotProps={
                mode === "presentation"
                  ? {
                      popper: {
                        container: parentRef?.current,
                      },
                    }
                  : {}
              }
            >
              <NewButton
                icon
                size="medium"
                variant="text"
                IconLeft={<ChevronLeftIcon />}
                onClick={handleToggle}
                sx={{
                  minWidth: "unset",
                  width: "24px",
                  height: "40px",
                  borderRadius: "0",
                  padding: "unset",
                  "&:hover": {
                    backgroundColor: "#595958",
                  },
                  "&:active": {
                    backgroundColor: "#6a6a69",
                  },
                  svg: {
                    color: "inherit",
                    width: "initial",
                    height: "initial",
                    transform: expanded ? "rotate(0)" : "rotate(180deg)",
                    transition: "transform 0.3s ease-in-out",
                    path: {
                      fillOpacity: expanded ? undefined : 1,
                    },
                  },
                }}
              />
            </Tooltip>
            {download ? (
              <Tooltip
                title="Download"
                placement="bottom"
                arrow
                slotProps={
                  mode === "presentation"
                    ? {
                        popper: {
                          container: parentRef?.current,
                        },
                      }
                    : {}
                }
              >
                <NewButton
                  icon
                  size="medium"
                  variant="text"
                  IconLeft={<DownloadAltIcon />}
                  onClick={handleDownload}
                  disabled={loading || !expanded}
                  sx={{
                    minWidth: "unset",
                    width: "40px",
                    height: "40px",
                    borderRadius: "0",
                    pointerEvents: loading || !expanded ? "none" : "auto",
                    "&:hover": {
                      backgroundColor: "#595958",
                    },
                    "&:active": {
                      backgroundColor: "#6a6a69",
                    },
                    svg: {
                      color: "inherit",
                      width: "initial",
                      height: "initial",
                      path: {
                        fill: "#FFFFFF",
                      },
                    },
                  }}
                />
              </Tooltip>
            ) : null}
            {fullScreenMode ? (
              <Tooltip
                title={
                  <Stack direction="row" alignItems="center" gap="8px">
                    <Typography
                      component="span"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="22px"
                      color="#FFFFFF"
                    >
                      {mode === "full-screen"
                        ? "Close full screen "
                        : "Full screen"}
                    </Typography>
                    {mode === "full-screen" ? (
                      <Typography
                        component="span"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="22px"
                        color="#FFFFFFB3"
                      >
                        Esc
                      </Typography>
                    ) : null}
                  </Stack>
                }
                placement="bottom"
                arrow
                slotProps={
                  mode === "presentation"
                    ? {
                        popper: {
                          container: parentRef?.current,
                        },
                      }
                    : {}
                }
              >
                <NewButton
                  icon
                  size="medium"
                  variant="text"
                  IconLeft={
                    mode === "full-screen" ? (
                      <CloseFullScreenModeIcon />
                    ) : (
                      <FullScreenModeAltIcon />
                    )
                  }
                  onClick={handleFullScreenMode}
                  disabled={loading || !expanded}
                  sx={{
                    minWidth: "unset",
                    width: "40px",
                    height: "40px",
                    borderRadius: "0",
                    backgroundColor:
                      mode === "full-screen" ? "#6a6a69" : "transparent",
                    pointerEvents: loading || !expanded ? "none" : "auto",
                    "&:hover": {
                      backgroundColor: "#595958",
                    },
                    "&:active": {
                      backgroundColor: "#6a6a69",
                    },
                    svg: {
                      color: "inherit",
                      width: "16px",
                      height: "16px",
                      path: {
                        fill: "#FFFFFF",
                      },
                    },
                  }}
                />
              </Tooltip>
            ) : null}
            {presentationMode ? (
              <Tooltip
                title={
                  // mode === "presentation"
                  //   ? "Close presenter view"
                  //   : "Presenter view"
                  <Stack direction="row" alignItems="center" gap="8px">
                    <Typography
                      component="span"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="22px"
                      color="#FFFFFF"
                    >
                      {mode === "presentation"
                        ? "Close presenter view "
                        : "Presenter view"}
                    </Typography>
                    {mode === "presentation" ? (
                      <Typography
                        component="span"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="22px"
                        color="#FFFFFFB3"
                      >
                        Esc
                      </Typography>
                    ) : null}
                  </Stack>
                }
                placement="bottom"
                arrow
                slotProps={
                  mode === "presentation"
                    ? {
                        popper: {
                          container: parentRef?.current,
                        },
                      }
                    : {}
                }
              >
                <NewButton
                  icon
                  size="medium"
                  variant="text"
                  IconLeft={<PresentationModeAltIcon />}
                  onClick={handlePresentationMode}
                  disabled={loading || !expanded}
                  sx={{
                    minWidth: "unset",
                    width: "40px",
                    height: "40px",
                    borderRadius: "0",
                    backgroundColor:
                      mode === "presentation" ? "#6a6a69" : "transparent",
                    pointerEvents: loading || !expanded ? "none" : "auto",
                    "&:hover": {
                      backgroundColor: "#595958",
                    },
                    "&:active": {
                      backgroundColor: "#6a6a69",
                    },
                    svg: {
                      color: "inherit",
                      width: "18px",
                      height: "16px",
                      path: {
                        fill: "#FFFFFF",
                      },
                    },
                  }}
                />
              </Tooltip>
            ) : null}
          </Stack>
        )}
        {(fullScreenMode || presentationMode || download) && !loading ? (
          <Stack height="16px" width="1px" bgcolor="#dedede" />
        ) : null}
        <Tooltip
          placement="bottom-start"
          arrow
          title={
            <Stack direction="row" alignItems="center" gap="8px">
              <Typography
                component="span"
                fontSize="14px"
                fontWeight="400"
                lineHeight="22px"
                color="#FFFFFF"
              >
                Exit deliverable
              </Typography>
            </Stack>
          }
          slotProps={
            mode === "presentation"
              ? {
                  popper: {
                    container: parentRef?.current,
                  },
                }
              : {}
          }
        >
          <NewButton
            icon
            size="medium"
            variant="text"
            IconLeft={<CloseAltIcon />}
            onClick={handleClose}
            sx={{
              minWidth: "unset",
              width: "40px",
              height: "40px",
              borderRadius: "0",
              "&:hover": {
                backgroundColor: "#595958",
              },
              "&:active": {
                backgroundColor: "#6a6a69",
              },
              svg: {
                color: "inherit",
                width: "16px",
                height: "16px",
                path: {
                  fill: "#FFFFFF",
                },
              },
            }}
          />
        </Tooltip>
      </Stack>
    </header>
  )
})

FloatingToolbar.displayName = "FloatingToolbar"
export default FloatingToolbar
