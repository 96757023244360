import {
  WebViewer,
  FloatingToolbar,
  LoadingIndicator,
} from "@cr/webreport-viewer"
import {Box, Stack} from "@mui/material"
import {useMutation, useQuery} from "@tanstack/react-query"
import {Fragment, useEffect, useRef, useState, type FC} from "react"
import {decodeToken} from "react-jwt"
import {Navigate, useNavigate, useParams} from "react-router-dom"
import AccessDenied from "../AccessDenied/AccessDenied"
import {useConfigProvider} from "../../config"
import {appInsights} from "../../config/appInsights"
import {
  deliverableEndpoints,
  FabricEvent,
  projectsEndpoints,
  utilitiesEndpoints,
} from "../../services"
import usePageStore from "../../stores/pageStore"
import type {
  DeliverableModel,
  FolderModel,
  ProjectAssignmentModel,
  Token,
} from "../../types"
import {contentTypeMap} from "../ViewFile/utils"

const ViewWebReport: FC = () => {
  const {id, folderId, reportId} = useParams<{
    id: string
    folderId: string
    reportId: string
  }>()
  const navigate = useNavigate()
  const {basename, token, faToken, apimKey, serverUrl} = useConfigProvider()
  const decoded = decodeToken<Token>(token)
  const [isCookie, setIsCookie] = useState<boolean>(false)
  const [mode, setMode] = useState<"normal" | "presentation" | "full-screen">(
    "normal",
  )
  const [loaded, setLoaded] = useState(false)
  const parentRef = useRef<HTMLDivElement | null>(null)
  const viewerRef = useRef<HTMLIFrameElement | null>(null)

  const {getUserProjectById, getFolderById} = projectsEndpoints()
  const {getDeliverable, downloadDeliverableFile} = deliverableEndpoints()
  const {sendFabricEvent} = utilitiesEndpoints()

  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["getProject", id],
    queryFn: () => getUserProjectById(id || ""),
  })

  const folderQuery = useQuery<FolderModel>({
    queryKey: ["getFolder", id, folderId],
    queryFn: () => {
      return getFolderById(id || "", folderId || "")
    },
  })

  const deliverableQuery = useQuery<DeliverableModel>({
    queryKey: ["getDeliverable", reportId],
    queryFn: () => getDeliverable(reportId || ""),
  })

  const deliverable = deliverableQuery.data

  const getDeliverableFileQuery = useQuery<File>({
    queryKey: ["downloadFile", reportId],
    queryFn: async () => {
      const deliverableName = deliverable?.deliverableName
      const fileName = deliverableName
        ? deliverableName
            .split("/")
            .pop()
            ?.match(/.{1,30}/g)
            ?.join("\n")
        : "defaultFileName"
      const extension = deliverable?.deliverableSource?.toLowerCase()
      const contentType = extension
        ? contentTypeMap[extension as keyof typeof contentTypeMap]
        : null
      const file = await downloadDeliverableFile(
        reportId || "",
        fileName || "",
        contentType,
        extension || "",
      )
      return file || Promise.reject(new Error("File not found"))
    },
    enabled: !!reportId && !!deliverable,
  })

  const fabricEventMutation = useMutation<any, Error, FabricEvent>({
    mutationFn: (payload) => sendFabricEvent(payload),
    mutationKey: ["sendNotification"],
  })

  useEffect(() => {
    if (!isCookie && decoded && serverUrl) {
      document.cookie = `token=${token}; Domain=.controlrisks.com; path=/; Max-Age=3600000; SameSite=None; Secure;`
      document.cookie = `jwt=${token}; Domain=.controlrisks.com; path=/; Max-Age=3600000; SameSite=None; Secure;`
      document.cookie = `faToken=${faToken}; Domain=.controlrisks.com; path=/; Max-Age=3600000; SameSite=None; Secure`
      document.cookie = `apimKey=${apimKey}; Domain=.controlrisks.com; path=/; Max-Age=3600000; SameSite=None; Secure`
      setIsCookie(true)
    }

    return () => {
      document.cookie = `token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure`
      document.cookie = `jwt=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure`
      document.cookie = `faToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure`
      document.cookie = `apimKey=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure`
    }
  }, [token, faToken, apimKey])

  const handleClose = () => {
    navigate(-1)
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setMode("normal")
        if (mode === "presentation" && document.fullscreenElement) {
          document.exitFullscreen()
        }
      }
    }
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        if (mode === "presentation") {
          setMode("normal")
        }
      }
    }

    document.addEventListener("fullscreenchange", handleFullScreenChange)
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
      document.removeEventListener("fullscreenchange", handleFullScreenChange)
    }
  }, [mode])

  const handleFullScreenMode = () => {
    if (mode === "full-screen") {
      setMode("normal")
    } else {
      setMode("full-screen")
      if (mode === "presentation" && document.fullscreenElement) {
        document.exitFullscreen()
      }
    }
  }

  const handlePresentationMode = () => {
    if (mode === "presentation") {
      setMode("normal")
      document.exitFullscreen()
    } else {
      setMode("presentation")
      parentRef.current
        ?.requestFullscreen({navigationUI: "hide"})
        .catch((err) => {
          console.info("Error attempting to enable full-screen mode:", err)
        })
    }
  }

  const handleFileDownload = async () => {
    if (getDeliverableFileQuery.data && deliverable) {
      const fileUrl = URL.createObjectURL(getDeliverableFileQuery?.data)
      const link = document.createElement("a")
      link.href = fileUrl
      link.download = `${deliverable.deliverableName || "file"}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      appInsights.trackEvent({
        name: "DOWNLOAD_WEB_REPORT",
        properties: {
          deliverableId: reportId,
          deliverableName: deliverable?.deliverableName,
          folderId: folderId,
          folderName: folderQuery?.data?.folderName,
          projectId: id,
          projectName:
            projectQuery.data?.projectAssignmentDisplayName ||
            projectQuery.data?.projectAssignmentName ||
            "",
          downloadedBy: decoded?.email || "",
        },
      })
      const fabricEventPayload = {
        type: "DOWNLOAD_WEB_REPORT".toLowerCase(),
        targetId: reportId!,
        targetName: deliverable?.deliverableName,
      } satisfies FabricEvent
      await fabricEventMutation.mutateAsync(fabricEventPayload)
    }
  }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage(deliverableQuery.data?.deliverableName || "General")
  }, [deliverableQuery.data?.deliverableName])

  useEffect(() => {
    const footer = document.getElementById("footer")
    if (footer) {
      footer.style.display = "none"
    }

    return () => {
      if (footer) {
        footer.style.display = "flex"
      }
    }
  }, [])

  const hasDeliverable = !!deliverable
  useEffect(() => {
    if (hasDeliverable) {
      appInsights.trackEvent({
        name: "VIEW_WEB_REPORT",
        properties: {
          deliverableId: reportId,
          deliverableName: deliverableQuery?.data?.deliverableName,
          folderId: folderId,
          folderName: folderQuery?.data?.folderName,
          projectId: id,
          projectName:
            projectQuery.data?.projectAssignmentDisplayName ||
            projectQuery.data?.projectAssignmentName ||
            "",
          viewedBy: decoded?.email || "",
        },
      })

      const fabricEventPayload = {
        type: "VIEW_WEB_REPORT".toLowerCase(),
        targetId: reportId!,
        targetName: deliverable.deliverableName,
      } satisfies FabricEvent

      fabricEventMutation.mutate(fabricEventPayload)
    }
  }, [hasDeliverable])

  if (
    projectQuery.isLoading ||
    folderQuery.isLoading ||
    deliverableQuery.isLoading ||
    getDeliverableFileQuery.isLoading
  ) {
    return (
      <Fragment>
        <FloatingToolbar
          mode={mode}
          loading
          handleClose={handleClose}
          handleDownload={() => {}}
          handleFullScreenMode={() => {}}
          handlePresentationMode={() => {}}
        />
        <LoadingIndicator />
      </Fragment>
    )
  }

  if (deliverableQuery?.data?.hasOwnProperty("title")) {
    return <AccessDenied resource="deliverable" />
  }

  if (deliverableQuery?.data?.expiryDate) {
    const deliverableExpiryDate = Date.parse(
      deliverableQuery?.data?.expiryDate
        ? deliverableQuery?.data?.expiryDate
        : "",
    )
    const currentDate = Date.parse(new Date().toISOString())

    if (currentDate > deliverableExpiryDate) {
      return (
        <AccessDenied
          resource="deliverable"
          resourceName={deliverableQuery?.data?.deliverableName}
        />
      )
    }
  }

  if (
    (!deliverableQuery.data?.enabled &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (!projectQuery.data?.enabled &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending) ||
    (!folderQuery.data?.isEnabled &&
      !folderQuery.isLoading &&
      !folderQuery.isFetching &&
      !folderQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  if (
    (folderId !== deliverableQuery.data?.folder?.folderId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (id !== deliverableQuery.data?.projectAssignmentId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    !isCookie
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  if (deliverableQuery?.data?.deliverableType === "report") {
    return (
      <Navigate
        to={`${basename}/${id}/folders/${folderId}/file/${reportId}`}
        replace
      />
    )
  }

  if (deliverableQuery?.data?.deliverableType === "dashboard") {
    return (
      <Navigate
        to={`${basename}/${id}/folders/${folderId}/dashboard/${reportId}`}
        replace
      />
    )
  }

  return (
    <Box
      component="main"
      paddingX="2rem"
      paddingTop="1rem"
      paddingBottom="3rem"
      width="100%"
    >
      <Stack direction="column" gap="0.5rem">
        <Stack direction="column" gap="0.5rem" component="section">
          <Box
            sx={{
              backgroundColor: mode === "full-screen" ? "unset" : "#FFFFFF",
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);",
              borderRadius: "0.5rem",
              padding: "2.5rem",
              position: "fixed",
              zIndex: mode === "full-screen" ? 1110 : 1010,
              height: "100vh",
              width: mode == "full-screen" ? "100vw" : "calc(100vw - 56px)",
              top: 0,
              right: 0,
            }}
          >
            {deliverableQuery.isLoading ||
            getDeliverableFileQuery.isLoading ||
            !deliverable ? (
              <Fragment>
                <FloatingToolbar
                  mode={mode}
                  loading
                  handleClose={handleClose}
                  handleDownload={() => {}}
                  handleFullScreenMode={() => {}}
                  handlePresentationMode={() => {}}
                />
                <LoadingIndicator />
              </Fragment>
            ) : (
              <Fragment>
                <div
                  ref={parentRef}
                  style={{
                    position: mode === "full-screen" ? "fixed" : "initial",
                    top: 0,
                    left: 0,
                    width: mode === "normal" ? "auto" : "100vw",
                    height: mode === "normal" ? "auto" : "100vh",
                    zIndex: mode === "full-screen" ? 1100 : 90,
                    backgroundColor:
                      mode === "full-screen" ? "#1b1b1bcc" : "transparent",
                  }}
                >
                  <FloatingToolbar
                    ref={parentRef}
                    mode={mode}
                    presentationMode
                    fullScreenMode
                    download={!deliverable?.restrictDownload}
                    loading={
                      deliverableQuery.isLoading ||
                      getDeliverableFileQuery.isLoading ||
                      !deliverable ||
                      !loaded
                    }
                    handleClose={handleClose}
                    handleDownload={handleFileDownload}
                    handleFullScreenMode={handleFullScreenMode}
                    handlePresentationMode={handlePresentationMode}
                    containerProps={{
                      style: {
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                      },
                    }}
                  />
                  <WebViewer
                    id="web-report"
                    ref={viewerRef}
                    aria-hidden="false"
                    title={deliverable.deliverableName}
                    aria-label="Web report"
                    width={
                      mode === "presentation"
                        ? window.innerWidth
                        : window.innerWidth - 56
                    }
                    height={
                      mode === "presentation"
                        ? window.innerHeight
                        : mode === "full-screen"
                        ? window.innerHeight - 48
                        : window.innerHeight - 88
                    }
                    src={`${serverUrl}/deliverables/${reportId}/webreport/index.html?subscription-key=${apimKey}&code=${faToken}`}
                    sandbox="allow-scripts allow-same-origin allow-popups"
                    referrerPolicy="no-referrer"
                    onLoad={() => {
                      setLoaded(true)
                    }}
                    style={{
                      border: "none",
                      height:
                        mode === "presentation"
                          ? "100%"
                          : mode === "full-screen"
                          ? "calc(100vh - 48px)"
                          : "calc(100vh - 88px)",
                      width: mode === "normal" ? "calc(100vw - 56px)" : "100vw",
                      position: mode === "presentation" ? "initial" : "fixed",
                      top:
                        mode === "presentation"
                          ? 0
                          : mode === "full-screen"
                          ? 48
                          : 88,
                      left: mode === "normal" ? 56 : 0,
                      zIndex: 90,
                      backgroundColor: "transparent",
                    }}
                  />
                  <LoadingIndicator
                    containerProps={{
                      component: "section",
                      direction: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        mode === "presentation"
                          ? window.innerWidth
                          : window.innerWidth - 56,

                      height:
                        mode === "presentation"
                          ? window.innerHeight
                          : mode === "full-screen"
                          ? window.innerHeight - 48
                          : window.innerHeight - 88,
                      sx: {
                        height:
                          mode === "presentation"
                            ? "100%"
                            : "calc(100vh - 88px)",
                        width:
                          mode === "normal" ? "calc(100vw - 56px)" : "100vw",
                        position: mode === "presentation" ? "initial" : "fixed",
                        display: mode === "presentation" ? "none" : "flex",
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                        left: mode === "normal" ? 56 : 0,
                        zIndex: 80,
                      },
                    }}
                  />
                </div>
              </Fragment>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

ViewWebReport.displayName = "ViewWebReport"
export default ViewWebReport
