import {
  Dispatch,
  SetStateAction,
  type FC,
  Fragment,
  useEffect,
  useState,
} from "react"
import {Box, Stack, TableCell, TableRow, Typography} from "@mui/material"
import type {DeliverableModel, FolderModel} from "../../../types"
import {Table, type TableHeadingProps} from "../table"
import {ReactSVG} from "react-svg"
import {
  getDeliverableExtension,
  iconMap,
} from "../../../helpers/getDeliverableExtension"
import {useNavigate, useParams} from "react-router-dom"
import {TextWithTooltip} from "../textWithTooltip"
import moment from "moment"
import {dateFormat} from "../../../helpers/formatDates"
import {DeliverableStatus} from "../../../types/enums/DeliverableStatus"
import {SortOptions} from "../../../types/SortOptions"
import {Tooltip} from "../../atoms/Tooltip"
import {
  DashboardIcon,
  FileIcon,
  StrictlyConfidentialFolderIcon,
  UploadIcon,
  WebReportIcon,
} from "../../../assets/icons"
import {useConfigProvider} from "../../../config"
import {useTranslation} from "react-i18next"

type Props = {
  folders: FolderModel[]
  deliverables: DeliverableModel[]
  searchText: string
  sortState: {
    sortBy: string
    setSortBy: Dispatch<SetStateAction<SortOptions>>
  }
}

const headingsWithoutDeliverables = [
  {
    name: "Name",
    field: "name",
    isSortable: true,
    sx: {
      width: "80%",
    },
  },
  {
    name: "Deliverable type",
    field: "",
    isSortable: false,
    sx: {
      maxWidth: "250px",
    },
  },
] satisfies TableHeadingProps[]

const headingsWithDeliverables = [
  {
    name: "Name",
    field: "name",
    isSortable: true,
    sx: {
      width: "25%",
    },
  },
  {
    name: "Description",
    field: "description",
    isSortable: true,
    sx: {
      width: "55%",
    },
  },
  {
    name: "Modified",
    field: "modified",
    isSortable: true,
    sx: {
      width: "10%",
    },
  },
  {
    name: "Created",
    field: "created",
    isSortable: true,
    sx: {
      width: "10%",
    },
  },
] satisfies TableHeadingProps[]

const FoldersListView: FC<Props> = (props) => {
  const {folders, deliverables, sortState, searchText} = props
  const [tableMode, setTableMode] = useState<"folders" | "deliverables">(
    "folders",
  )
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {id} = useParams<{id: string}>()
  const {basename} = useConfigProvider()

  const handleNavigate = (deliverable: DeliverableModel) => {
    if (deliverable.deliverableType === "dashboard") {
      navigate(
        `${basename}/${id}/folders/${deliverable.folder.folderId}/dashboard/${deliverable.id}`,
      )
    } else {
      navigate(
        `${basename}/${id}/folders/${deliverable.folder.folderId}/file/${deliverable.id}`,
      )
    }
  }

  useEffect(() => {
    if (searchText.length > 0) {
      setTableMode("deliverables")
    } else {
      setTableMode("folders")
    }
  }, [searchText.length, deliverables])
  return (
    <Table
      headings={
        tableMode == "deliverables"
          ? headingsWithDeliverables
          : headingsWithoutDeliverables
      }
      style={{
        paddingTop: 0,
        height: "calc(100% - 121px)",
      }}
      tableProps={{
        stickyHeader: true,
      }}
      sortState={sortState}
      tableContainerProps={{
        sx: {
          boxShadow: "none",
          borderRadius: 0,
          padding: "0",
          boxSizing: "border-box",
          maxHeight: "100%",
          overflow: "auto",
          backgroundColor: "transparent",
          paddingRight: "0.5rem",

          "&:hover": {
            paddingRight: "0.25rem",
          },
        },
      }}
    >
      {folders.map((folder, i) => (
        <TableRow
          key={folder.folderId}
          onClick={() => navigate(`folders/${folder.folderId}`)}
          sx={{
            backgroundColor: "#FFFFFF",
            transition: "all 0.2s ease-in-out",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#EEEEEE",
            },
          }}
        >
          <TableCell
            sx={{
              fontSize: "0.875rem",
              fontWeight: 400,
              color: "#1A1A1A",
              lineHeight: "1.192rem",
              padding: "0.75rem 1rem",
              height: "2.5rem",
            }}
          >
            <Stack direction="row" gap={1}>
              {folder.strictlyConfidential ? (
                <StrictlyConfidentialFolderIcon />
              ) : (
                <Box
                  component="span"
                  sx={{
                    "& svg path": {fill: "#053747"},
                  }}
                >
                  <ReactSVG src="/icons/folder.svg" />
                </Box>
              )}
              <TextWithTooltip
                text={folder.folderName}
                limit={tableMode === "folders" ? 45 : 20}
                textStyle={{
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  lineHeight: "1.192rem",
                  color: "#1A1A1A",
                }}
                tooltipProps={{
                  placement: "bottom-start",
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "#FFFFFF",
                      height: "26px",
                      maxWidth: "100vw",
                      color: "#242D35",
                      textAlign: "left",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      paddingX: "8px",
                      paddingY: "2px",
                      borderWidth: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#1B1B1B",
                      borderRadius: "0px",
                    },
                  },
                }}
              />
            </Stack>
          </TableCell>
          {searchText.length > 0 ? (
            <Fragment>
              <TableCell sx={{padding: "0.75rem 1rem", height: "2.5rem"}}>
                {null}
              </TableCell>
              <TableCell sx={{padding: "0.75rem 1rem", height: "2.5rem"}}>
                {null}
              </TableCell>
              <TableCell sx={{padding: "0.75rem 1rem", height: "2.5rem"}}>
                {null}
              </TableCell>
            </Fragment>
          ) : (
            <Fragment>
              <TableCell
                sx={{
                  padding: "0.75rem 1rem",
                  height: "2.5rem",
                }}
              >
                <Stack
                  direction="row"
                  gap="2.5rem"
                  alignItems="center"
                  marginTop="auto"
                >
                  <Stack direction="row" gap="0.25rem" alignItems="center">
                    <Tooltip title={t("Dashboards")} arrow placement="right">
                      <Box>
                        <DashboardIcon />
                      </Box>
                    </Tooltip>
                    <Typography
                      fontSize="0.875rem"
                      color="#242D35"
                      lineHeight="1.192rem"
                    >
                      {folder.totalUrlDeliverables}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="0.25rem" alignItems="center">
                    <Tooltip title={t("Web reports")} arrow placement="right">
                      <Box>
                        <WebReportIcon />
                      </Box>
                    </Tooltip>
                    <Typography
                      fontSize="0.875rem"
                      color="#242D35"
                      lineHeight="1.192rem"
                    >
                      {folder.totalWebReportDeliverables}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="0.25rem" alignItems="center">
                    <Tooltip title={t("Files")} arrow placement="right">
                      <Box>
                        <FileIcon />
                      </Box>
                    </Tooltip>
                    <Typography
                      fontSize="0.875rem"
                      color="#242D35"
                      lineHeight="1.192rem"
                    >
                      {folder.totalFilesDeliverables}
                    </Typography>
                  </Stack>
                </Stack>
              </TableCell>
            </Fragment>
          )}
        </TableRow>
      ))}
      {searchText.length > 0 &&
        deliverables.map(
          (deliverable, i) =>
            deliverable.status === DeliverableStatus.CLEAN && (
              <TableRow
                key={deliverable.id}
                onClick={() => handleNavigate(deliverable)}
                sx={{
                  backgroundColor: "#FFFFFF",
                  transition: "all 0.2s ease-in-out",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#EEEEEE",
                  },
                }}
              >
                <TableCell
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color: "#1A1A1A",
                    lineHeight: "1.192rem",
                    padding: "0.75rem 1rem",
                    height: "2.5rem",
                  }}
                >
                  <Stack
                    flexDirection="row"
                    gap={1}
                    justifyContent={"space-between"}
                    alignContent={"center"}
                  >
                    <Stack direction="row" spacing={1}>
                      {deliverable.strictlyConfidential ? (
                        <Tooltip
                          arrow
                          title="Strictly confidential"
                          placement="right"
                        >
                          <div>
                            <ReactSVG
                              src={
                                deliverable.deliverableType === "dashboard"
                                  ? "/icons/file-icons/Dashboard-confidential.svg"
                                  : deliverable.deliverableType === "web"
                                  ? "/icons/file-icons/Web-report-confidential.svg"
                                  : `/icons${
                                      iconMap[
                                        getDeliverableExtension(
                                          deliverable.deliverableUrl || "",
                                        )
                                      ]
                                    }-confidential.svg`
                              }
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <ReactSVG
                          src={
                            deliverable.deliverableType === "dashboard"
                              ? "/icons/file-icons/Dashboard.svg"
                              : deliverable.deliverableType === "web"
                              ? "/icons/file-icons/Web-report.svg"
                              : `/icons${
                                  iconMap[
                                    getDeliverableExtension(
                                      deliverable.deliverableUrl || "",
                                    )
                                  ]
                                }.svg`
                          }
                        />
                      )}
                      <TextWithTooltip
                        tooltipProps={{arrow: true, placement: "bottom-start"}}
                        tooltipStyle={{
                          "& .MuiTooltip-arrow": {
                            // move arrow to the left
                            left: "10px !important",
                            transform: "none !important",
                          },
                        }}
                        textStyle={{
                          fontSize: "0.875rem",
                          fontWeight: 400,
                          lineHeight: "1.192rem",
                          color: "#1A1A1A",
                        }}
                        text={deliverable.deliverableName}
                        limit={20}
                      />
                    </Stack>

                    {deliverable?.folder.folderName.toLowerCase() ===
                    "client uploads" ? (
                      <Stack>
                        <UploadIcon fill="#053747" height="1rem" width="1rem" />
                      </Stack>
                    ) : null}
                  </Stack>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color: "#1A1A1A",
                    lineHeight: "1.192rem",
                    padding: "0.75rem 1rem",
                    height: "2.5rem",
                  }}
                >
                  <TextWithTooltip
                    tooltipProps={{arrow: true}}
                    text={deliverable.deliverableDescription}
                    limit={60}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color: "#1A1A1A",
                    lineHeight: "1.192rem",
                    padding: "0.75rem 1rem",
                    height: "2.5rem",
                  }}
                >
                  {!deliverable.lastUpdateTimestamp
                    ? ""
                    : moment(deliverable.lastUpdateTimestamp).format(
                        dateFormat,
                      )}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color: "#1A1A1A",
                    lineHeight: "1.192rem",
                    padding: "0.75rem 1rem",
                    height: "2.5rem",
                  }}
                >
                  {moment(deliverable.createdTimestamp).format(dateFormat) ||
                    ""}
                </TableCell>
              </TableRow>
            ),
        )}
    </Table>
  )
}

export default FoldersListView
