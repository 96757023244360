import {FC} from "react"
import {Drawer, Skeleton, Stack, Typography} from "@mui/material"
import {ProjectAssignmentModel} from "../../../types"
import {CancelIcon} from "../../../assets/icons"
import {NewButton} from "../../atoms"
import {useTranslation} from "react-i18next"
import {TextWithTooltip} from "../../molecules"

type ChecklistDrawerProps = {
  open: boolean
  handleClose: () => void
  project?: ProjectAssignmentModel
}

const ChecklistDrawer: FC<ChecklistDrawerProps> = (props) => {
  const {open, handleClose, project} = props
  const {t} = useTranslation()
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      component="aside"
      sx={{
        "& .MuiDrawer-modal": {
          marginTop: "88px",
          marginLeft: "56px",
          height: "calc(100% - 144px)",
        },
        "& .MuiDrawer-paper": {
          marginTop: "88px",
          marginLeft: "56px",
          height: "calc(100% - 144px)",
          bgcolor: "white",
          boxShadow: "none",
        },
        "& MuiPaper-root": {
          marginTop: "88px",
          marginLeft: "56px",
          height: "calc(100% - 144px)",
        },
      }}
    >
      <Stack height="calc(100vh - 88px)" flexGrow={1}>
        <Stack height="100%" width="620px" maxWidth="100vw" component="main">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="1rem"
            component="header"
            width="100%"
            paddingX="24px"
            paddingY="16px"
            borderBottom="1px solid #DEDEDE"
          >
            {project ? (
              (project.projectAssignmentDisplayName || "").length > 39 ? (
                <TextWithTooltip
                  text={project.projectAssignmentDisplayName || ""}
                  suffix={t("checklist")}
                  limit={39}
                  textStyle={{
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "27.24px",
                    color: "#053747",
                  }}
                  tooltipStyle={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "#FFFFFF",
                      height: "26px",
                      maxWidth: "100vw",
                      color: "#242D35",
                      textAlign: "left",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      paddingX: "8px",
                      paddingY: "2px",
                      borderWidth: "0.5px",
                      borderStyle: "solid",
                      borderColor: "#1B1B1B",
                      borderRadius: "0px",
                    },
                  }}
                />
              ) : (
                <Typography
                  component="h2"
                  fontWeight="600"
                  fontSize="20px"
                  lineHeight="27.24px"
                  color="#053747"
                >
                  {project.projectAssignmentDisplayName}&nbsp;{t("checklist")}
                </Typography>
              )
            ) : (
              <Skeleton width="50%" height="20px" />
            )}

            <NewButton
              icon
              disableRipple
              variant="text"
              onClick={handleClose}
              IconLeft={<CancelIcon fill="#6A6A69" />}
              sx={{
                padding: "0",
                minWidth: "auto",
                height: "auto",
                width: "auto",

                svg: {
                  width: "30px",
                  height: "30px",
                },
              }}
            />
          </Stack>
          <Stack
            component="section"
            paddingX="32px"
            paddingY="16px"
            flexGrow={1}
            maxHeight="calc(100vh - 282px)"
            overflow="auto"
            fontSize="16px"
            lineHeight="24px"
            color="#242D35"
            fontWeight="400"
            sx={{
              "ul, ol": {
                marginLeft: "24px",
              },

              "ul li::marker": {
                fontSize: "14px",
              },

              "ul li": {
                listStyleType: "disc",
              },
            }}
          >
            {project ? (
              <article
                id="checklist-content"
                dangerouslySetInnerHTML={{
                  __html: project.projectCheckList || "",
                }}
              />
            ) : (
              <Skeleton width="100%" height="20px" />
            )}
          </Stack>
          <Stack
            component="footer"
            direction="row"
            justifyContent="flex-end"
            paddingX="24px"
            paddingY="16px"
            borderTop="1px solid #DEDEDE"
            marginTop="auto"
            width="100%"
          >
            <NewButton
              variant="filled"
              color="primary"
              onClick={handleClose}
              text={t("Close")}
              size="medium"
              sx={{
                width: "136px",
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  )
}

ChecklistDrawer.displayName = "ChecklistDrawer"

export default ChecklistDrawer
