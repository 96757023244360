import {FloatingToolbar, LoadingIndicator} from "@cr/webreport-viewer"
import {Box, Stack, Typography} from "@mui/material"
import {useMutation, useQuery} from "@tanstack/react-query"
import {Fragment, useEffect, useRef, useState, type FC} from "react"
import {decodeToken} from "react-jwt"
import {Navigate, useNavigate, useParams} from "react-router-dom"
import {FileViewer, VideoViewer} from "../../components"
import {useConfigProvider} from "../../config"
import {
  deliverableEndpoints,
  FabricEvent,
  projectsEndpoints,
  utilitiesEndpoints,
} from "../../services"
import usePageStore from "../../stores/pageStore"
import type {
  DeliverableModel,
  FolderModel,
  ProjectAssignmentModel,
  Token,
} from "../../types"
import AccessDenied from "../AccessDenied/AccessDenied"
import {contentTypeMap, previewableTypes, videoTypes} from "../ViewFile/utils"
import {appInsights} from "../../config/appInsights"
import {NoPreviewIcon} from "../../assets/icons"

const getViewerType = (type: string) => {
  const typeMap = {
    fdf: "pdf",
    xfdf: "pdf",
  } as {[key: string]: string}

  if (typeMap[type]) {
    return typeMap[type]
  }

  return type
}

const ViewFile: FC = () => {
  const {id, folderId, fileId} = useParams<{
    id: string
    folderId: string
    fileId: string
  }>()
  const navigate = useNavigate()
  const {basename, token} = useConfigProvider()
  const decoded = decodeToken<Token>(token)
  const [mode, setMode] = useState<"normal" | "presentation" | "full-screen">(
    "normal",
  )
  const [hasFileError, setHasFileError] = useState<boolean>(false)
  const [loaded, setLoaded] = useState(false)
  const parentRef = useRef<HTMLDivElement | null>(null)
  const viewerRef = useRef<HTMLIFrameElement | null>(null)

  const {getUserProjectById, getFolderById} = projectsEndpoints()
  const {getDeliverable, downloadDeliverableFile} = deliverableEndpoints()
  const {sendFabricEvent} = utilitiesEndpoints()

  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["getProject", id],
    queryFn: () => getUserProjectById(id || ""),
  })

  const folderQuery = useQuery<FolderModel>({
    queryKey: ["getFolder", id, folderId],
    queryFn: () => {
      return getFolderById(id || "", folderId || "")
    },
  })

  const deliverableQuery = useQuery<DeliverableModel>({
    queryKey: ["getDeliverable", fileId],
    queryFn: () => getDeliverable(fileId || ""),
  })

  const deliverable = deliverableQuery.data
  const previewFileType = getViewerType(
    deliverable?.deliverableSource?.toLowerCase() || "",
  )

  const getDeliverableFileQuery = useQuery<File>({
    queryKey: ["downloadFile", fileId],
    queryFn: async () => {
      const deliverableName = deliverable?.deliverableName
      const fileName = deliverableName
        ? deliverableName
            .split("/")
            .pop()
            ?.match(/.{1,30}/g)
            ?.join("\n")
        : "defaultFileName"
      const extension = deliverable?.deliverableSource?.toLowerCase()
      const contentType = extension
        ? contentTypeMap[extension as keyof typeof contentTypeMap]
        : null
      const file = await downloadDeliverableFile(
        fileId || "",
        fileName || "",
        contentType,
        extension || "",
      )
      return file || Promise.reject(new Error("File not found"))
    },
    enabled: !!fileId && !!deliverable,
  })

  const fabricEventMutation = useMutation<any, Error, FabricEvent>({
    mutationFn: (payload) => sendFabricEvent(payload),
    mutationKey: ["sendNotification"],
  })

  const handleClose = () => {
    const isClientUploads = ["client uploads", "your uploads"].includes(
      folderQuery.data?.folderName.toLowerCase() || "",
    )

    if (isClientUploads) {
      navigate(-1)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setMode("normal")
        if (mode === "presentation" && document.fullscreenElement) {
          document.exitFullscreen()
        }
      }
    }
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        if (mode === "presentation") {
          setMode("normal")
        }
      }
    }

    document.addEventListener("fullscreenchange", handleFullScreenChange)
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
      document.removeEventListener("fullscreenchange", handleFullScreenChange)
    }
  }, [mode])

  const handleFullScreenMode = () => {
    if (mode === "full-screen") {
      setMode("normal")
    } else {
      setMode("full-screen")
      if (mode === "presentation" && document.fullscreenElement) {
        document.exitFullscreen()
      }
    }
  }

  const handlePresentationMode = () => {
    if (mode === "presentation") {
      setMode("normal")
      document.exitFullscreen()
    } else {
      setMode("presentation")
      parentRef.current
        ?.requestFullscreen({navigationUI: "hide"})
        .catch((err) => {
          console.info("Error attempting to enable full-screen mode:", err)
        })
    }
  }

  const handleFileDownload = async () => {
    if (getDeliverableFileQuery.data && deliverable) {
      const fileUrl = URL.createObjectURL(getDeliverableFileQuery?.data)
      const link = document.createElement("a")
      link.href = fileUrl
      link.download = `${deliverable.deliverableName || "file"}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      appInsights.trackEvent({
        name: ["client uploads", "your uploads"].includes(
          folderQuery.data?.folderName.toLowerCase() || "",
        )
          ? "DOWNLOAD_FILE"
          : "DOWNLOAD_DELIVERABLE",
        properties: {
          deliverableId: fileId,
          deliverableName: deliverable?.deliverableName,
          folderId: folderId,
          folderName: folderQuery?.data?.folderName,
          projectId: id,
          projectName:
            projectQuery.data?.projectAssignmentDisplayName ||
            projectQuery.data?.projectAssignmentName ||
            "",
          downloadedBy: decoded?.email || "",
        },
      })
      const fabricEventPayload = {
        type: ["client uploads", "your uploads"].includes(
          folderQuery.data?.folderName.toLowerCase() || "",
        )
          ? "download_file"
          : "download_deliverable",
        targetId: fileId!,
        targetName: deliverable?.deliverableName,
      } satisfies FabricEvent
      await fabricEventMutation.mutateAsync(fabricEventPayload)
    }
  }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage(deliverableQuery.data?.deliverableName || "General")
  }, [deliverableQuery.data?.deliverableName])

  useEffect(() => {
    const footer = document.getElementById("footer")
    if (footer) {
      footer.style.display = "none"
    }

    return () => {
      if (footer) {
        footer.style.display = "flex"
      }
    }
  }, [])

  const hasDeliverable = !!deliverable
  useEffect(() => {
    if (hasDeliverable) {
      appInsights.trackEvent({
        name: ["client uploads", "your uploads"].includes(
          folderQuery.data?.folderName.toLowerCase() || "",
        )
          ? "VIEW_FILE"
          : "VIEW_DELIVERABLE",
        properties: {
          deliverableId: fileId,
          deliverableName: deliverableQuery?.data?.deliverableName,
          folderId: folderId,
          folderName: folderQuery?.data?.folderName,
          projectId: id,
          projectName:
            projectQuery.data?.projectAssignmentDisplayName ||
            projectQuery.data?.projectAssignmentName ||
            "",
          viewedBy: decoded?.email || "",
        },
      })

      const fabricEventPayload = {
        type: ["client uploads", "your uploads"].includes(
          folderQuery.data?.folderName.toLowerCase() || "",
        )
          ? "view_file"
          : "view_deliverable",
        targetId: fileId!,
        targetName: deliverable.deliverableName,
      } satisfies FabricEvent

      fabricEventMutation.mutate(fabricEventPayload)
    }
  }, [hasDeliverable])

  useEffect(() => {
    if (
      hasDeliverable &&
      getDeliverableFileQuery.isSuccess &&
      !previewableTypes.includes(deliverable?.deliverableSource || "") &&
      !videoTypes.includes(deliverable?.deliverableSource || "")
    ) {
      setLoaded(true)
    }
  }, [
    getDeliverableFileQuery.isSuccess,
    hasDeliverable,
    deliverable?.deliverableSource,
  ])

  if (
    projectQuery.isLoading ||
    folderQuery.isLoading ||
    deliverableQuery.isLoading ||
    getDeliverableFileQuery.isLoading
  ) {
    return (
      <Fragment>
        <FloatingToolbar
          mode={mode}
          loading
          handleClose={handleClose}
          handleDownload={() => {}}
          handleFullScreenMode={() => {}}
          handlePresentationMode={() => {}}
        />
        <LoadingIndicator />
      </Fragment>
    )
  }

  if (deliverableQuery?.data?.hasOwnProperty("title")) {
    return <AccessDenied resource="deliverable" />
  }

  if (deliverableQuery?.data?.expiryDate) {
    const deliverableExpiryDate = Date.parse(
      deliverableQuery?.data?.expiryDate
        ? deliverableQuery?.data?.expiryDate
        : "",
    )
    const currentDate = Date.parse(new Date().toISOString())

    if (currentDate > deliverableExpiryDate) {
      return (
        <AccessDenied
          resource="deliverable"
          resourceName={deliverableQuery?.data?.deliverableName}
        />
      )
    }
  }

  if (
    (!deliverableQuery.data?.enabled &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (!projectQuery.data?.enabled &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending) ||
    (!folderQuery.data?.isEnabled &&
      !folderQuery.isLoading &&
      !folderQuery.isFetching &&
      !folderQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  if (
    (folderId !== deliverableQuery.data?.folder?.folderId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (id !== deliverableQuery.data?.projectAssignmentId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  if (deliverableQuery?.data?.deliverableType === "web") {
    return (
      <Navigate
        to={`${basename}/${id}/folders/${folderId}/web-report/${fileId}`}
        replace
      />
    )
  }

  if (deliverableQuery?.data?.deliverableType === "dashboard") {
    return (
      <Navigate
        to={`${basename}/${id}/folders/${folderId}/dashboard/${fileId}`}
        replace
      />
    )
  }

  return (
    <Box
      component="main"
      paddingX="2rem"
      paddingTop="1rem"
      paddingBottom="3rem"
      width="100%"
    >
      <Stack direction="column" gap="0.5rem">
        <Stack direction="column" gap="0.5rem" component="section">
          <Box
            sx={{
              backgroundColor: mode === "full-screen" ? "unset" : "#FFFFFF",
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);",
              borderRadius: "0.5rem",
              padding: "2.5rem",
              position: "fixed",
              zIndex: mode === "full-screen" ? 1110 : 1010,
              height: "100vh",
              width: mode == "full-screen" ? "100vw" : "calc(100vw - 56px)",
              top: 0,
              right: 0,
            }}
          >
            {deliverableQuery.isLoading ||
            getDeliverableFileQuery.isLoading ||
            !deliverable ||
            !getDeliverableFileQuery.data ? (
              <Fragment>
                <FloatingToolbar
                  mode={mode}
                  loading
                  handleClose={handleClose}
                  handleDownload={() => {}}
                  handleFullScreenMode={() => {}}
                  handlePresentationMode={() => {}}
                />
                <LoadingIndicator />
              </Fragment>
            ) : (
              <Fragment>
                <div
                  ref={parentRef}
                  style={{
                    position: mode === "full-screen" ? "fixed" : "initial",
                    top: 0,
                    left: 0,
                    width: mode === "normal" ? "auto" : "100vw",
                    height: mode === "normal" ? "auto" : "100vh",
                    zIndex: mode === "full-screen" ? 1100 : 90,
                    backgroundColor:
                      mode === "full-screen" ? "#1b1b1bcc" : "transparent",
                  }}
                >
                  <FloatingToolbar
                    ref={parentRef}
                    mode={mode}
                    presentationMode
                    fullScreenMode
                    download={!deliverable?.restrictDownload}
                    loading={
                      deliverableQuery.isLoading ||
                      getDeliverableFileQuery.isLoading ||
                      !deliverable ||
                      !loaded
                    }
                    handleClose={handleClose}
                    handleDownload={handleFileDownload}
                    handleFullScreenMode={handleFullScreenMode}
                    handlePresentationMode={handlePresentationMode}
                    containerProps={{
                      style: {
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                      },
                    }}
                  />
                  {videoTypes.includes(
                    deliverable?.deliverableSource?.toLowerCase() || "",
                  ) ? (
                    <Box
                      id="video-player"
                      ref={viewerRef}
                      title={deliverable.deliverableName}
                      width={
                        mode === "presentation"
                          ? window.innerWidth
                          : window.innerWidth - 56
                      }
                      height={
                        mode === "presentation"
                          ? window.innerHeight
                          : mode === "full-screen"
                          ? window.innerHeight - 48
                          : window.innerHeight - 88
                      }
                      sx={{
                        border: "none",
                        height:
                          mode === "presentation"
                            ? "100%"
                            : mode === "full-screen"
                            ? "calc(100vh - 48px)"
                            : "calc(100vh - 88px)",
                        width:
                          mode === "normal" ? "calc(100vw - 56px)" : "100vw",
                        position: mode === "presentation" ? "initial" : "fixed",
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                        left: mode === "normal" ? 56 : 0,
                        zIndex: 90,
                        backgroundColor: "transparent",
                      }}
                    >
                      <VideoViewer
                        file={getDeliverableFileQuery.data}
                        fileType={previewFileType}
                        setIsViewerLoaded={setLoaded}
                      />
                    </Box>
                  ) : previewableTypes.includes(
                      deliverable?.deliverableSource?.toLowerCase() || "",
                    ) ? (
                    <Box
                      id="file-viewer"
                      ref={viewerRef}
                      title={deliverable.deliverableName}
                      display="flex"
                      width={
                        mode === "presentation"
                          ? window.innerWidth
                          : window.innerWidth - 56
                      }
                      height={
                        mode === "presentation"
                          ? window.innerHeight
                          : mode === "full-screen"
                          ? window.innerHeight - 48
                          : window.innerHeight - 88
                      }
                      sx={{
                        border: "none",
                        height:
                          mode === "presentation"
                            ? "100%"
                            : mode === "full-screen"
                            ? "calc(100vh - 48px)"
                            : "calc(100vh - 88px)",
                        width:
                          mode === "normal" ? "calc(100vw - 56px)" : "100vw",
                        position: mode === "presentation" ? "initial" : "fixed",
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                        left: mode === "normal" ? 56 : 0,
                        zIndex: 90,
                        backgroundColor: "transparent",
                      }}
                    >
                      <FileViewer
                        disableHeader
                        file={getDeliverableFileQuery.data}
                        fileType={previewFileType}
                        setIsViewerLoaded={setLoaded}
                        fileName={deliverable?.deliverableName}
                        error={{hasFileError, setHasFileError}}
                      />
                    </Box>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      flexGrow={0}
                      padding="32px"
                      gap="24px"
                      width={
                        mode === "presentation"
                          ? window.innerWidth
                          : window.innerWidth - 56
                      }
                      height={
                        mode === "presentation"
                          ? window.innerHeight
                          : mode === "full-screen"
                          ? window.innerHeight - 48
                          : window.innerHeight - 88
                      }
                      sx={{
                        border: "none",
                        height:
                          mode === "presentation"
                            ? "100%"
                            : mode === "full-screen"
                            ? "calc(100vh - 48px)"
                            : "calc(100vh - 88px)",
                        width:
                          mode === "normal" ? "calc(100vw - 56px)" : "100vw",
                        position: mode === "presentation" ? "initial" : "fixed",
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                        left: mode === "normal" ? 56 : 0,
                        zIndex: 99,
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <NoPreviewIcon height="48px" width="48px" />
                      <Typography
                        color="#595958"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        No preview available for this file
                      </Typography>
                    </Stack>
                  )}
                  <LoadingIndicator
                    containerProps={{
                      component: "section",
                      direction: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        mode === "presentation"
                          ? window.innerWidth
                          : window.innerWidth - 56,

                      height:
                        mode === "presentation"
                          ? window.innerHeight
                          : mode === "full-screen"
                          ? window.innerHeight - 48
                          : window.innerHeight - 88,
                      sx: {
                        height:
                          mode === "presentation"
                            ? "100%"
                            : "calc(100vh - 88px)",
                        width:
                          mode === "normal" ? "calc(100vw - 56px)" : "100vw",
                        position: mode === "presentation" ? "initial" : "fixed",
                        display:
                          mode === "presentation" ||
                          deliverable.deliverableSource?.toLowerCase() === "ogg"
                            ? "none"
                            : "flex",
                        top:
                          mode === "presentation"
                            ? 0
                            : mode === "full-screen"
                            ? 48
                            : 88,
                        left: mode === "normal" ? 56 : 0,
                        zIndex: 80,
                      },
                    }}
                  />
                </div>
              </Fragment>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

ViewFile.displayName = "ViewFile"
export default ViewFile
